.ConvertionTunnel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ConvertionTunnel_steps {
  display: flex;
  padding-top: 20px;
  align-items: flex-end;
}

.ConvertionTunnel_step {
  flex: 1 1;
  text-align: center;
  text-decoration: none;
  color: #525252;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.ConvertionTunnel_step_number_active {
  color: rgba(113, 3, 236, 0.7);
  font-weight: bold;
}

.ConvertionTunnel_step_number_past {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_name_active {
  color: #525252;
}

.ConvertionTunnel_step_index {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 30px;
  color: #9e9e9e;
}

.ConvertionTunnel_step_index_active {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_index:hover {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_bar {
  height: 2px;
  margin: 10px 0;
  transition-duration: 1.5s;
  width: 0;
}

.ConvertionTunnel_step_bar_active {
  height: 2px;
  margin: 10px 0;
  background-color: rgba(113, 3, 236, 0.7);
  transition-duration: 1.5s;
  width: 100%;
}

.ConvertionTunnel_step_bar_past {
  height: 2px;
  margin: 10px 0;
  background-color: rgba(113, 3, 236, 0.7);
  transition-duration: 1.5s;
  width: 100%;
}

.ConvertionTunnel_question {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3 1;
  margin-left: -20000px;
  transition-duration: 1s;
}

.ConvertionTunnel_question_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3 1;
  margin-left: 0px;
  transition-duration: 1s;
  justify-content: center;
}

.ConvertionTunnel_question_description {
  background-color: white;
}

.ConvertionTunnel_responses {
  margin-top: 20px;
  display: flex;
}

.ConvertionTunnel_actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.ConvertionTunnel_action {
  text-decoration: none;
  border: 2px solid rgba(113, 3, 236, 0.7);
  padding: 5px 10px;
  color: rgba(113, 3, 236, 0.7);
  border-radius: 10px;
}

.ConvertionTunnel_action:hover {
  background-color: rgba(113, 3, 236, 0.7);
  color: white;
}

.ConvertionTunnel_action_prev {
  margin-left: 30px;
}

.ConvertionTunnel_action_next {
  margin-right: 30px;
}
