@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');

.Gameboard-container {
  border-radius: 5px;
  width: 60%;
  background: #66566d;
  margin: 0px 5px;
  cursor: url('../../styles/img/Game/pink.cur'), default;
  cursor: url('../../styles/img/Game/pink.cur'), pointer;
}

.Gameboard-container div,
.Gameboard-container img {
  cursor: url('../../styles/img/Game/pink.cur'), default;
  cursor: url('../../styles/img/Game/pink.cur'), pointer;
}

.Gameboard-title {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

.Gameboard-container h1,
.Gameboard-container h2,
.Gameboard-container h3 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 1.5rem !important;
  margin: 15px;
}

.Gameboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Gameinfo {
  margin: 10px 30px;
  padding: 10px;
  background: #2d1837;
}

.Gameinfo p {
  margin: 15px;
}

.YouAreHere {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
}

.YouAreHere h4 {
  font-family: 'Pangolin', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  transform: rotate(4deg);
  color: #fff;
  margin: 15px;
}

.Timer h1 {
  font-family: 'Roboto';
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}

.timer {
  font-family: 'Montserrat';
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;
}

/* GAMEBOARD HEADER */

.Gameboard-Header {
  position: absolute;
  width: 23%;
  height: 11%;
  z-index: 4;
  border-bottom-left-radius: 5% 15%;
  background: linear-gradient(90deg, #d4507a, #dc4b50);
  right: 0;
}

.GameBoardHeader_cards {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  height: 100%;
  cursor: pointer;
}

.GameBoardHeader_card {
  background-repeat: no-repeat;
}

.GameBoardHeader_card p {
  opacity: 0;
}

.card_primary {
  background-image: url(../../styles/img/emotions/minicard/mini_primary_emotion_card.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}

.card_secondary_1 {
  background-image: url(../../styles/img/emotions/minicard/mini_emotion_card_1.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_2 {
  background-image: url(../../styles/img/emotions/minicard/mini_emotion_card_2.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_3 {
  background-image: url(../../styles/img/emotions/minicard/mini_emotion_card_3.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_4 {
  background-image: url(../../styles/img/emotions/minicard/mini_emotion_card_4.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.GameBoardHeader_card_found {
  background-color: #2d1937;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  color: white;
  margin-top: 60%;
  font-size: smaller;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  opacity: 1;
}

.card_disabled {
  opacity: 0.3;
}
