.container {
  width: 900px;
  margin: auto;
}

/* GAME */
.Game {
  display: flex;
  display: -webkit-flex;
  background: #2d1837;
}

.GameWindow {
  height: 100vh;
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  align-items: center;
  -webkit-align-items: center;
  background: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
}

.GameWindow-DemoMulti {
  height: 100vh;
  background: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
}

.GameWindow-Boards {
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  align-items: center;
  -webkit-align-items: center;
  height: 100vh;
}

/* BUTTONS */

.ButtonAction {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin: 15px auto;
  font-weight: bold;

  background-size: 200%;
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url('./styles/img/Game/pink.cur'), pointer;
}

.Action {
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url('./styles/img/Game/pink.cur'), pointer;
}

.Contact {
  background-image: linear-gradient(90deg, #792887, #cf1c83, #d93a5b, #e76322);
  background-position: left;
  border: 1px solid #454545;
  box-shadow: 0px 0px 5px #454545;
  transition: background-position ease-out 0.5s;
  cursor: url('./styles/img/Game/pink.cur'), pointer;
}

.Action:hover,
.Contact:hover {
  background-position: right;
}

.Return {
  color: #454545;
  border: 2px solid #454545;
}

.Cancel {
  background-color: #000;
  cursor: url('./styles/img/Game/pink.cur'), pointer;
}
.Cancel:hover {
  background-color: darkslategray;
  cursor: url('./styles/img/Game/pink.cur'), pointer;
}

.Hide {
  display: none;
}

.Show {
  display: block;
  width: 100%;
}

.Buttons {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}

.Buttons button {
  margin: 10px;
}

/* CURSOR CLICKED */

.cursor {
  width: 0;
  height: 0;
  border: 2px solid transparent;
  background-color: transparent;
  backface-visibility: hidden;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* opacity: 1; */
}

.cursor-clicked {
  width: 0rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 1s;
  backface-visibility: hidden;
  /* opacity: 0; */
}

.cursor p {
  font-size: 0px;
  color: #fff;
  font-weight: 400;
  backface-visibility: hidden;
  /* opacity: 1; */
  position: absolute;
}

.cursor-clicked p {
  font-size: 40px;
  color: transparent;
  visibility: visible;
  pointer-events: none;
  backface-visibility: hidden;
  animation: fade 1s;
}

@keyframes fade {
  0% {
    color: transparent;
    font-size: 0px;
    transform: translateY(0%);
  }
  20% {
    color: #fff;
  }
  80% {
    color: #fff;
  }
  100% {
    font-size: 40px;
    transform: translateY(-100%);
    color: transparent;
  }
}
