.FinalStep {
  display: flex;
}

.FinalStep_container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: 2s;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
}

.FinalStep_container_name {
  display: flex;
}

.FinalStep_resume {
  display: flex;
}

.FinalStep_resume_title {
  margin-left: 20px;
}

.FinalStep_left,
.FinalStep_right {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
