.InfoResponse {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
  background-color: white;
  padding: 20px;
  margin-right: 20px;
  border-radius: 8px;
  font-size: 14px;
}

.InfoResponse_goals {
  display: flex;
  text-align: center;
}

.InfoResponse_goals_title {
  display: flex;
  align-items: center;
}

.InfoResponse_goal {
  border: 1px solid #9e9e9e;
  padding: 10px;
  margin: 10px;
  flex: 1 1;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}
