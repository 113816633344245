@font-face {
  font-family: 'Urania';
  src: url('Urania_Czech.woff') format('woff');
}

@font-face {
  font-family: 'Karmatic Arcade';
  font-style: normal;
  font-weight: normal;
  src: local('Karmatic Arcade'), url('./Score/ka1.woff') format('woff');
}

/* MUG */

.Align-Center {
  align-self: center;
}

/* MODALS */

.ModalTricky-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background h3 {
  color: #fff;
  margin-bottom: 0px;
}

.ModalTricky-Background-Time {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  backdrop-filter: blur(5px);
}

.ModalTricky-NoClick {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-NoClick2 {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 82;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.Prevention-Message {
  padding: 20px;
  color: #fff;
}

.ModalTricky-button {
  width: 100%;
}

.ModalTricky-button button {
  position: absolute;
  transform: translateX(-60%);
}

.ModalTrickyObject-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7%;
  left: 20%;
  width: 60%;
  height: 76.3%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background-Position {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6.8%;
  left: 20.1%;
  width: 59.7%;
  height: 74%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  height: 70%;
  width: 70%;
}

.ModalTricky-TimeEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 70%;
}

.ModalTricky-Animation {
  width: 247.359px;
  height: 187.219px;
  margin-bottom: 70px;
}

.ModalTricky-Container-Powerchord {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
}

.ModalTricky-WrongObject {
  height: 100%;
  width: 100%;
}

.ModalTricky-WrongObject img {
  animation: showwrongitem 0.5s ease;
  animation-fill-mode: forwards;
  height: 100%;
}

/* MODAL INTRO */

.ModalTricky-Intro {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Intro-Container-DemoMulti {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}

.ModalTricky-Intro-Card-DemoMulti {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  padding: 0px 30px;
}

.ModalTricky-Intro-Text-DemoMulti {
  display: flex;
  flex-direction: column;
  line-height: 1.3rem;
}

.ModalTricky-Intro-Title-DemoMulti {
  font-family: 'Karmatic Arcade';
  font-size: 2rem;
  margin-bottom: 10px;
  color: #fff;
}

.ModalTricky-Intro-Title2 {
  font-family: 'Karmatic Arcade';
  font-size: 2rem;
  margin-bottom: 10px;
  color: #000;
}

.ModalTricky-Intro-TextGrey {
  color: #999999;
}

.fa-clock {
  color: #999999;
  font-size: 2rem;
}

.ModalTricky-Intro-Img img {
  object-fit: cover;
  height: 400px;
  width: 350px;
}

/* MODAL LETTER */

.opened-letter {
  height: 90% !important;
}

.Letter-Open {
  width: 250%;
  margin-bottom: 1px !important;
}

.Letter-Text {
  width: 165%;
  margin-bottom: 0 !important;
  position: relative;
}

.Letter-Text-Cont {
  position: absolute;
  top: 30%;
  width: 20%;
}

.Letter-Text-Cont p {
  color: #000000;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
  color: #fff;
}

.ModalTricky-p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.ModalObjectTricky-p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.ModalObjectTricky-p p {
  color: #fff;
}

.ModalObjectTricky-p img {
  width: 20%;
  margin-top: 30px;
  margin-bottom: 0;
}

.ModalObjectTricky-p .MugImg {
  width: 80%;
  margin-left: 70px;
}

.ModalObject-Button {
  display: flex;
}

.ModalTricky-Emotion-Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
}

.ModalTricky-Emotion-Card-Text {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(10%);
  width: 100%;
  text-align: center;
}

.ModalTricky-Emotion-Card-Text > p {
  color: #fff;
  width: 80%;
  margin: 2rem auto;
}

.ModalTricky-Emotion-Cards {
  display: flex;
  flex-direction: row;
}

.ModalTricky-Emotion-Cards-P {
  width: 20%;
  color: #000;
  background: #fff;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 5px hsl(0, 90%, 53%), 0 0 0 12px hsl(0, 0%, 100%);
}

.ModalTricky-Emotion-Card h5 {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h4 {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h3 {
  color: #fff;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
}

.button-continue {
  position: absolute;
  bottom: 2%;
}

.Emotion-Card-Container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 5px;
  width: 90%;
  height: 90%;
}

.ModalTricky-Emotion-Card img {
  width: 95%;
  align-self: center;
  margin-bottom: 0px !important;
  position: relative;
}

.EmotionCont {
  display: flex;
}

.Emotion-Card {
  position: relative;
  width: 100%;
}

.Emotion-Card-Title-1 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-1 {
  position: absolute;
  top: 95px;
  left: 42px;
  width: 70%;
}

.Emotion-Card-Title-2 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-2 {
  position: absolute;
  top: 90px;
  left: 42px;
  width: 70%;
}

.ModalTricky-Emotion-Card-Text .fa-check-circle:before {
  margin: 5px 5px 0px 0px !important;
  color: #fff !important;
}

.Hide-Modal {
  display: none !important;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord img {
  width: 100%;
}

.ModalTricky-Computer {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 300px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Success {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid green;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Error {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid red;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Email {
  display: flex;
  padding: 0.5vw;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  background: #23345a;
  border: 0.5vw solid #000000;
  border-radius: 2vw;
}

.ModalTricky-Computer-Email-p {
  background: #fff;
  border-radius: 1vw;
}

.ModalTricky-Computer-Password label {
  color: #fff;
  margin-right: 10px;
}

.ModalTricky-Computer-Password .Error {
  color: red;
  font-weight: bold;
}

.ModalTricky-Computer-Password .Success {
  color: green;
  font-weight: bold;
}

.ModalTricky-Computer-Email p {
  margin: 20px !important;
  font-weight: 500 !important;
  font-size: 1.4vw;
  color: #23345a !important;
}

.ModalTricky-Computer-Email .Button-Email {
  display: flex;
  justify-content: center;
}

.ModalTricky-Computer-Email .ValidButton,
.ModalTricky-Computer-Email .CancelButton {
  /* margin: 20px; */
  z-index: 1;
  cursor: pointer;
}

.ModalTricky-Computer-Email input {
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #000;
  font-size: 1.4vw;
  font-weight: 500;
  color: #23345a;
}

.word1 {
  width: 3vw;
}

.word2 {
  width: 7.8vw;
}

.word3 {
  width: 5.6vw;
}

.word4 {
  width: 10vw;
}

.word1:focus,
.word2:focus,
.word3:focus,
.word4:focus {
  outline: none;
}

.PowerChord {
  width: 100%;
  position: relative;
}

.textPowerChord {
  position: absolute;
  color: #fff;
  top: 5%;
  font-size: 2rem;
}

.btnPowerChord {
  font-size: 1.5rem;
  position: absolute;
  top: 30%;
  left: 50%;
}

.Img-vision {
  width: 60%;
  align-self: center;
}
/* BOARD */

.ModalTricky-Board-Container {
  background-image: url('../../styles/img/Game/Tableau_background_etire_t0nqm9.svg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Board-Melanie img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 18%;
  left: 34.5%;
  cursor: pointer;
}

.Board-Chloe img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 27%;
  left: 61%;
  cursor: pointer;
}

.Board-Marion img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 55%;
  left: 41%;
  cursor: pointer;
}

.Board-Pierre img {
  position: absolute;
  z-index: 2;
  height: 21%;
  top: 69.5%;
  left: 64.5%;
  cursor: pointer;
}

/*----TELEPHONE----*/

.ModalTricky-Phone-Number form {
  position: relative;
}

.ModalTricky-Phone-Number img {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  width: 54vw;
}

.phone-number-input {
  position: absolute;
  top: 8.5%;
  left: 31.5%;
  transform: skew(-18deg, -2deg);
  background-color: transparent;
  font-size: 2.2vw;
  width: 23%;
  height: 10%;
  border-style: none;
}

.phone-number-input:focus {
  outline: none;
}

.container_pad {
  display: inline-flex;
  flex-wrap: wrap;
  width: 15vw;
  position: absolute;
  top: 29%;
  left: 27%;
  transform: skew(-6deg);
  white-space: nowrap;
}

.phone_button {
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  background-color: black;
  border-radius: 8px;
  display: inline-block;
  margin: 3px;
}

.inner-phone-button {
  text-align: center;
  width: 4.5vw;
  height: 2.2vw;
  font-size: 1rem;
  color: black;
  text-decoration: none;
  display: inline-block;
  background-color: white;
  border-radius: inherit;
  border: 2px solid black;
  padding: 0 5px;
  text-transform: uppercase;
  transform: translate3d(-5px, -4px, 0);
  transition: transform 80ms ease;
  transition-delay: 50ms;
}

.inner-phone-button:active {
  transform: translate3d(0, 0, 0);
  transition: transform 80ms ease;
}

.inner-phone-button:focus {
  outline: none;
}

.fa-backspace {
  color: black;
}

/*------------------*/

#PostitMelanie {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 22%;
  left: 69%;
}

#PostitChloe {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 27%;
  left: 76.3%;
}

#PostitMarion {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 37%;
  left: 70.5%;
}

#PostitPierre {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 44.3%;
  left: 77%;
}

.Board-Buttons {
  display: flex;
}

/* ANIMATIONS */

.ItemToShow {
  height: 60%;
  width: 100%;
}

.ItemToShow img {
  animation: showitem 0.5s linear;
  animation-fill-mode: forwards;
  height: 100%;
}

.zoomleftmulti {
  animation: zoomleftmulti 0.5s linear;
  animation-fill-mode: forwards;
  z-index: 5;
  position: absolute;
  display: block;
}

.zoomleftmulti img {
  height: 100%;
}

@keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes zoomleftmulti {
  from {
    opacity: 1;
    top: 61%;
    left: 41%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 40%;
    left: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}

/* ICONS SVG */

.next-ingame {
  filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 20px;
}

.check-ingame {
  filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 30px !important;
}

@keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {
  .ModalTricky-Emotion-Card-Text {
    transform: translateY(33%);
  }
}

@media screen and (max-width: 1410px) {
  .ModalTricky-Emotion-Card h5 {
    font-size: 1rem;
  }
  .ModalTricky-Emotion-Card p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1250px) {
  .ModalTricky-Emotion-Card p {
    font-size: 0.8rem;
  }
}
