@font-face {
  font-family: 'ArcadeClassic';
  font-style: normal;
  font-weight: normal;
  src: local('ArcadeClassic'), url('ARCADECLASSIC.woff') format('woff');
}

.ModalTricky-Scoreboard-Container-DemoMulti {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
  border-radius: 5px;
  border: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  border-image-slice: 1;
}

.ModalTricky-Scoreboard-Scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
}

.ModalTricky-Scoreboard-info-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 15%;
  font-family: 'ArcadeClassic';
}

.ModalTricky-Scoreboard-Scores-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 22%;
  font-family: 'ArcadeClassic';
}

.ModalTricky-Scoreboard-DemoMulti {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
  width: 35%;
  height: 55%;
}

.ModalTricky-Scoreboard-Title-DemoMulti {
  text-align: center;
  font-family: 'Karmatic Arcade';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-Scoreboard-players-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  text-align: center;
}

.ModalTricky-Scoreboard-User-DemoMulti {
  width: 100%;
  display: flex;
  padding: 8px 0px 8px 0px;
  background-image: linear-gradient(
    90deg,
    #e76322,
    #d93a5b,
    #cf1c83,
    #792887,
    #552d88
  );
  color: #fff;
  text-align: center;
}

.ModalTricky-Scoreboard-players-DemoMulti:hover {
  background: #e8e8e8;
  font-weight: bold;
}

.ModalTricky-Scoreboard-players-DemoMulti:hover .col-20 {
  color: #666666;
}

.col-10 {
  width: 10%;
}

.col-20 {
  width: 20%;
  cursor: pointer;
}

.col-50 {
  width: 50%;
  text-align: left;
  padding-left: 15px;
}

.scoreboard-pseudo-DemoMulti {
  font-weight: bold;
  color: #e3505d;
}

.scoreboard-rank-DemoMulti {
  font-weight: bold;
  color: #4d3990;
}

.score-selected {
  border: 1px solid #fff;
  border-radius: 5px;
}

.scoreboard-user-DemoMulti {
  font-weight: bold;
  color: #fff;
}
