.Header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Header_logo img {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
