.Input_label {
  display: flex;
  flex: 1 1;
}

.Input_input {
  flex: 1 1;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 20px;
}
