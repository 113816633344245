.UserBoard-container {
  border-radius: 5px;
  width: 20%;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  position: relative;
}

.UserBoard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 10px;
}

.UserBoard-Block {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
}

.UserBoard-Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  text-align: center;
}

.UserBoard-Modal button {
  width: 100%;
  margin: 10px 10px;
  padding: 10px 5px;
}

.UserBoard-Modal .ModalTricky-p {
  font-size: 1rem;
  margin: 0px;
}

.MGBoard-Block {
  display: flex;
  padding: 15px;
  margin: 50px 5px 5px 5px;
  justify-content: space-between;
}

.UserBoard-participant {
  display: flex;
  height: 15vh;
  position: relative;
  margin: 2px 5px;
  filter: grayscale(100%);
}

.UserBoard-Localparticipant {
  display: flex;
  height: 15vh;
  position: relative;
  margin: 2px 5px;
  background: #fff;
}

.UserBoard-Player {
  z-index: 1;
  margin: 0px 0px 5px 10px;
  position: absolute;
  bottom: 0%;
  display: inline-block;
}

.UserBoard-Admin-Player {
  z-index: 1;
  margin: 0px 0px 5px 10px;
  position: absolute;
  width: 50%;
  bottom: 0%;
  display: inline-block;
}

.UserBoard-Player-Coup {
  border: 1px solid #fff;
  border-radius: 15.5px;
  background: #fff;
  width: 2vw;
}

.UserBoard-Player-Name {
  margin: 0px 10px 0px 10px;
  width: 100%;
}

.UserBoard-Player-Name-Admin {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2%;
}

.UserBoard-Player-Name-Admin h4 {
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-participant img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoard-participant video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoard-Status p {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
}

.UserBoard-RightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-Panel {
  width: 100%;
  height: 100%;
}

#sign-out-userboard img {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 0px 0px 50%;
  color: #000;
  width: 7%;
  height: 16%;
  padding: 6px;
  background: #fff;
  font-size: 0.8rem;
}

#microphone-onboard img {
  position: absolute;
  bottom: 3%;
  right: 3%;
}

#microphone-onboard img {
  padding: 5px;
  color: #000;
  width: 7%;
  height: 16%;
  font-size: 0.8rem;
}

.portal-onboard {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  padding: 0px 0px 0px 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.46);
  box-shadow: 0 16px 48px 0 rgba(0, 55, 83, 0.2);
  border-radius: 23px;
}

.portal-onboard:hover {
  background: #fff;
}

.portal-onboard p:hover {
  color: #000;
}

.portal-onboard p {
  font-size: 0.8vw;
  color: #fff;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  padding: 5px;
}

#video-onboard img {
  position: absolute;
  bottom: 3%;
  right: 13%;
}

#video-onboard img {
  padding: 5px;
  color: #000;
  width: 7%;
  height: 16%;
}

/* REMOTE PARTICIPANT */

.UserBoard-RemotePlayer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 15vh;
  padding: 0px 0px 0px 10px;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-RemoteAdmin {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-Admin-RemotePlayer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  padding: 0px 0px 0px 10px;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-RemotePlayer h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  text-shadow: 1px 1px 2px #000;
}

.UserBoardRemote-Webcam {
  display: flex;
  height: 130px;
}

.UserBoardRemote-Webcam img {
  width: 19.5%;
  height: 150px;
  object-fit: cover;
  position: absolute;
}

.UserBoardRemote-Webcam video {
  width: 18.9%;
  height: 150px;
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
}

.UserBoardRemote-Block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 15vh;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Block-Admin {
  display: flex;
  justify-content: space-between;
  width: 19.5%;
  margin-top: 10px;
  height: 15vh;
  position: absolute;
  top: 78vh;
}

.UserBoardRemote-Block-Admin-0 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Block-Admin-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 59%;
}

.UserBoardRemote-Block-Admin-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 37.5%;
}

.UserBoardRemote-Block-Admin-3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 16.5%;
}

.UserBoardRemote-Block-Admin-4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Admin-Block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15vh;
  margin-top: 10px;
  position: relative;
}

.UserBoardRemote-Block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Block video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Admin-Block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Admin-Block video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* SKELETON */

.UserBoard-Skelton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    60.64deg,
    #2a348d 0.99%,
    #cf1c83 50.78%,
    #ef7c00 98.25%
  );
}

.UserBoard-Skelton-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 230px;
}

.UserBoard-RightPanel-Type {
  position: absolute;
  top: 4%;
  left: 4%;
}

.UserBoard-RightPanel-Type h4 {
  font-size: 1rem;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-Remote-Player-Name-Admin {
  margin: 0px 10px 0px 10px;
  width: 100%;
}

.UserBoard-Remote-Player-Name-Admin h4 {
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-Remote-Participant-Admin {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.UserBoard-Game-Master {
  position: absolute;
  top: 5%;
  left: 15px;
}

.UserBoard-Game-Master h4 {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-TutorialStatus {
  position: absolute;
}

.UserBoard-Ready-Light {
  border: none;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 20px;
  height: 1.1vw;
  width: 1.1vw;
  border-radius: 50%;
  box-shadow: 0 0 1px var(--green), 0 0 5px var(--green), 0 0 10px var(--green),
    0 0 40px var(--green), inset 0 0 5px var(--green),
    inset 0 0 7px var(--green);
}

.UserBoard-NotReady-Light {
  border: none;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 20px;
  height: 1.1vw;
  width: 1.1vw;
  border-radius: 50%;
  box-shadow: 0 0 1px var(--red), 0 0 5px var(--red), 0 0 10px var(--red),
    0 0 40px var(--red), inset 0 0 5px var(--red), inset 0 0 7px var(--red);
}

.UserBoard-Admin-Player-Location {
  z-index: 1;
  position: absolute;
  display: inline-block;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  left: 30%;
  margin: 2px;
  padding: 4px;
  animation: appear 0.5s ease-out 3;
  transform-origin: top center;
}

.UserBoard-Admin-Player-Location h4 {
  font-size: 0.8vw;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

@keyframes appear {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* BUBBLES */

.bubble {
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 5px #818181;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease;
}

.bubble p {
  font-size: 1rem;
  color: #000;
  text-align: center;
}

.pos1 {
  position: absolute;
  z-index: 10;
  top: 25%;
  right: 70%;
}

.pos2 {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 70%;
}

.pos3 {
  position: absolute;
  z-index: 10;
  top: 70%;
  right: 70%;
}

.bubble::after {
  content: '';
  position: absolute;
  border-right: 0px solid transparent;
  border-left: 40px solid transparent;
  border-top: 20px solid #fff;
  top: 100px;
  right: 20%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
