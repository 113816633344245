.Button button {
  color: #525252;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Button.Button-header button {
  margin: 0;
  padding: 0;
}

.Button a {
  text-decoration: none;
}

.Button.Button-header button .Button_line {
  height: 2px;
  width: 0;
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  margin-top: 5px;
}

/* Animation */
.Button.Button-header button:hover .Button_line {
  transition-duration: 0.5s;
  width: 100%;
}
