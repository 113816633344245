.Tunnel-Container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Tunnel-Form-Choice {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 70%;
  height: 30%;
  margin-top: 50px;
  position: relative;
}

.Tunnel-Form-Choice-Action {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 30%;
  margin-top: 50px;
  position: relative;
}

.Tunnel-Form-Choice-Univers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 30%;
  margin-top: 50px;
}

.Tunnel-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 75vh;
}

.Tunnel-Form h3 {
  margin-top: 50px;
  font-size: 26px;
}

.fa-check-circle:before {
  font-size: 35px;
  color: red;
}

.Tunnel-Choice-Container-1,
.Tunnel-Choice-Container-2,
.Tunnel-Choice-Container-3,
.Tunnel-Choice-Container-4,
.Tunnel-Choice-Container-5,
.Tunnel-Choice-Container-6 {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  transition: box-shadow 0.5s;
}

.Tunnel-Choice-Container-1 p,
.Tunnel-Choice-Container-2 p,
.Tunnel-Choice-Container-3 p,
.Tunnel-Choice-Container-4 p,
.Tunnel-Choice-Container-5 p,
.Tunnel-Choice-Container-6 p,
.Tunnel-Choice-Container-1-choosen p,
.Tunnel-Choice-Container-2-choosen p,
.Tunnel-Choice-Container-3-choosen p,
.Tunnel-Choice-Container-4-choosen p,
.Tunnel-Choice-Container-5-choosen p,
.Tunnel-Choice-Container-6-choosen p {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Choice-Container-1:hover,
.Tunnel-Choice-Container-2:hover,
.Tunnel-Choice-Container-3:hover,
.Tunnel-Choice-Container-4:hover,
.Tunnel-Choice-Container-5:hover,
.Tunnel-Choice-Container-6:hover {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.Tunnel-Choice-Container-1-choosen,
.Tunnel-Choice-Container-2-choosen,
.Tunnel-Choice-Container-3-choosen,
.Tunnel-Choice-Container-4-choosen,
.Tunnel-Choice-Container-5-choosen,
.Tunnel-Choice-Container-6-choosen {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 2px solid rgba(113, 3, 236, 0.7);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.Tunnel-Choice-Check {
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 16px;
  right: 16px;
}

.Tunnel-Choice-Check-Good {
  position: absolute;
  top: 16px;
  right: 16px;
}

.Tunnel-Form-Footer {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 10vh;
}

.Back-Button {
  background: #fff;
  text-align: center;
  color: rgba(113, 3, 236, 0.5);
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.Back-Button:hover {
  background: #fff;
  text-align: center;
  color: rgba(113, 3, 236, 1);
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
  border: 2px solid rgba(113, 3, 236, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.Next-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
}

.Tunnel-Choice-Infos {
  display: flex;
  position: absolute;
  top: -30px;
  width: 95vw;
  height: 60vh;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  z-index: 5;
}

.Tunnel-Choice-Infos-Action {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  width: 87%;
  height: 220px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Tunnel-Choice-Infos-Action h3 {
  margin: 15px;
}

.Tunnel-Choice-Infos-Action span {
  line-height: 22px;
}

.Tunnel-Choice-Infos-Action div {
  margin: 5px 15px 5px 15px;
}

.Tunnel-Choice-Infos-Left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
  padding: 10px 10px 10px 30px;
}

.Tunnel-Choice-Infos-Left-Objectif-Container {
  /* margin: 0px 0px 40px 0px; */
}

.Tunnel-Choice-Infos-Left-Duration-Container {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-top: 40px;
}

.Tunnel-Choice-Infos-Left-Duration-Container p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.Tunnel-Choice-Infos-Right-Big-Image {
  background-image: url('../../styles/img/georg-arthur-pflueger-TeWwYARfcM4-unsplash.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 500px;
  height: 250px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image-2 {
  background-image: url('../../styles/img/antevasin-nguyen-ccaQyDwMCZ0-unsplash.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 230px;
  height: 220px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image-1 {
  background-image: url('../../styles/img/matteo-vistocco-u_CvU45FLH8-unsplash.jpg');
  background-repeat: no-repeat;
  background-position: 30% center;
  width: 230px;
  height: 220px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image {
  display: flex;
  justify-content: center;
}

.Tunnel-Choice-Infos-Button {
  position: absolute;
  top: 2%;
  right: 1%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Tunnel-Choice-Infos-Button p {
  width: 12px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.3);
}

.Tunnel-Choice-Infos-Left-Duration-Container span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.Tunnel-Choice-Infos-Left-Objectif-Container h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin: 0px 0px 10px 0px;
}

.Tunnel-Choice-Infos-Left-Objectif {
  display: flex;
}

.Tunnel-Choice-Infos-Left-Objectif p {
  margin: 5px 5px 5px 0px;
  width: 40%;
}

.Tunnel-Choice-Infos-Left-Title h1 {
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  width: 70%;
  margin: 20px 0px;
}

.Tunnel-Choice-Infos-Left-Title span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.Tunnel-Choice-Infos-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.Tunnel-Choice-Univers-Img img {
  align-self: center;
  width: 60%;
}

.Tunnel-Choice-Univers-Img-ETI {
  align-self: center;
  width: 50%;
}

.Tunnel-Choice-Univers-Title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 5px;
}

.Tunnel-Form-Recap {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.Tunnel-Recap-Container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  transition: 2s;
}

.Tunnel-Contact-Container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.Tunnel-Recap-Global h3 {
  margin: 50px 0px 0px 0px !important;
  text-align: center;
  font-size: 26px;
}

.Tunnel-Recap-Global p {
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}

.Tunnel-Contact-Global {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px;
  position: relative;
}

.Tunnel-Recap-Global {
  margin-top: 20px;
}

.Tunnel-Contact-Form {
  display: flex;
  flex-direction: column;
  padding: 53px;
}

.Tunnel-Contact-Infos-Text {
  text-align: center;
}

.Tunnel-Contact-Infos-Text p {
  margin-top: 5px;
  font-size: 12px;
}

.Tunnel-Contact-Infos-Text h3 {
  margin-bottom: 5px;
}

.Tunnel-Contact-Form h3 {
  margin: 20px 20px 20px 10px;
}

.Tunnel-Contact-Form label {
  padding: 10px 10px;
  margin: 20px 20px 20px 0px;
  width: 20%;
}

.Tunnel-Contact-Form input {
  padding: 10px 10px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #d6d1d5;
  width: 60%;
  margin: 20px 0px 20px 20px;
}

.Tunnel-Contact-Form input:hover,
.Tunnel-Contact-Form input:focus {
  border: 1px solid #7103ec;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #7103ec !important;
}

.Tunnel-Contact-Credential {
  display: flex;
  align-items: center;
  width: 99.5%;
  height: 14%;
  border-top: 1px solid #c2c8ff;
  border-bottom: 1px solid #c2c8ff;
  background: #f5f6ff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  bottom: 0%;
}
.Tunnel-Contact-Credential input {
  margin: 20px 20px 20px 10px;
  padding: 0px;
  width: 10%;
}

.Tunnel-Contact-Error {
  font-size: 10px;
  color: red;
  margin: 18px 20px 0px 14px;
}

.Tunnel-Contact-Credential span {
  font-size: 13px;
  margin: 10px;
}

.Tunnel-Contact-Input {
  display: flex;
  justify-content: space-around;
}

.Send-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
  position: absolute;
  top: 115%;
  left: 115%;
}

.Tunnel-Recap-Choice-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-1-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 2s;
}

.Tunnel-Recap-Choice-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-2-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 3s;
}

.Tunnel-Recap-Choice-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-3-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 4s;
}

.Tunnel-Recap-Choice-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-4-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 5s;
}

.Tunnel-Recap-Choice-1-transition span,
.Tunnel-Recap-Choice-2-transition span,
.Tunnel-Recap-Choice-3-transition span,
.Tunnel-Recap-Choice-4-transition span {
  font-size: 16px;
  font-weight: bold;
}

.Tunnel-Recap-Choice-1-transition p,
.Tunnel-Recap-Choice-2-transition p,
.Tunnel-Recap-Choice-3-transition p,
.Tunnel-Recap-Choice-4-transition p {
  font-weight: bold;
  font-size: 12px !important;
  color: #7103ec;
  text-align: left;
}

.Tunnel-Recap-Pastille-Container {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.Tunnel-Recap-Pastille-1 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-1-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 2s;
}

.Tunnel-Recap-Pastille-2 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-2-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 3s;
}

.Tunnel-Recap-Pastille-3 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-3-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 4s;
}

.Tunnel-Recap-Pastille-4 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-4-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 5s;
}

.Tunnel-Recap-Pastille-1-transition p,
.Tunnel-Recap-Pastille-2-transition p,
.Tunnel-Recap-Pastille-3-transition p,
.Tunnel-Recap-Pastille-4-transition p {
  margin: 12px 0px !important;
}

.Tunnel-Contact-InfoSent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Generate-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
  width: 50%;
}

/* .Tunnel-Recap-Hide-1,
.Tunnel-Recap-Hide-2,
.Tunnel-Recap-Hide-3,
.Tunnel-Recap-Hide-4 {
  width: 20%;
} */

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
}

.Tunnel-Leave {
  position: absolute;
  background-color: #fff;
  opacity: 75%;
  backdrop-filter: blur(40em);
  width: 100%;
  height: 97vh;
  top: 6%;
  z-index: 50;
  display: flex;
  justify-content: center;
}

.Tunnel-Leave-Container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.Tunnel-Leave-Button {
  display: flex;
}

.Tunnel-Choice-Container-Desc {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
