.Dashboard-container {
  border: 3px solid transparent;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  border-radius: 5px;
  width: 20%;
  position: relative;
}

.Dashboard-container h2 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
  font-size: 1.3rem;
}

.Dashboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Dashboard-Logo {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
  padding: 20px 0px;
}

.Dashboard-Logo img {
  width: 50%;
  height: 100%;
}

.Dashboard-Logo h3 {
  font-family: 'Pangolin', Arial, Helvetica, sans-serif;
  color: #fff;
  position: absolute;
  margin: 0px;
  top: 4%;
  right: 10%;
  font-size: 1rem;
  transform: rotate(-6deg);
}

/* SOUND GAME */

.Sound-Game {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  padding: 10px 20px;
  background: #2d1837;
  margin: 5px;
  /* border: 4px solid #45354c; */
  opacity: 0.5;
  position: relative;
  cursor: pointer;
}

.Sound-Game img {
  width: 20px;
  height: 20px;
}

/* TURN COUNTER */

.TurnCounter-container,
.Time-container {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  background: #2d1837;
  margin: 5px;
  position: relative;
}

.Time-container {
  color: #fff;
  font-weight: 300;
}

.TurnCounter-container2 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  border: 4px solid red;
}

.TurnCounter-container2 h2 {
  background: none !important;
  margin-bottom: 5%;
}

.TurnCounter-container-time {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  border: 4px solid #45354c;
  position: relative;
  bottom: 0;
  left: 25%;
}

/* OBJECTS */

.Object-Item-Container {
  margin-bottom: 20px;
}

.Object-title {
  padding: 10px 0px 0px 10px;
  font-size: 1.5vw !important;
}

.ObjectList {
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  position: relative;
  background: #2d1837;
  margin: 5px;
}

.Object-square {
  width: 100px;
  height: 100px;
  background: #635868;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.Object-square img {
  width: 80px;
  height: 80px;
}

.Object-square .Object-Image {
  cursor: pointer;
}

.Object-name {
  padding: 10px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter .example-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave .example-leave-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.TurnCounter-container2 h2 {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 1rem !important;
  font-weight: 400 !important;
  opacity: 30%;
}

.timer .TimerText {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  opacity: 30%;
}

.timer .TimerText-time {
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10-time {
  text-transform: uppercase;
  text-align: center;
  color: #a30000;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10 {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  color: #a30000;
}

.NbClick2 {
  font-size: 1.7rem;
  font-weight: 700 !important;
}

.NbClick2--big {
  font-size: 1.7rem;
  font-weight: 700 !important;
  animation: big2 1s ease;
  animation-fill-mode: backwards;
}

@keyframes big2 {
  from {
    font-size: 1.7rem;
    font-weight: 700 !important;
  }
  to {
    font-size: 2.4rem;
    font-weight: 1200 !important;
  }
}

.NbClick {
  font-size: 1rem;
  font-weight: 500 !important;
}

.NbClick--big {
  font-size: 1rem;
  font-weight: 500 !important;
  animation: big 1s ease;
  animation-fill-mode: backwards;
}

@keyframes big {
  from {
    font-size: 1rem;
    font-weight: 500 !important;
  }
  to {
    font-size: 2rem;
    font-weight: 1000 !important;
  }
}
