/* MODALEMOTIONCARD */

.ModalEmotionCards {
  height: 100%;
}
.ModalEmotionCard_final {
  height: 100%;
  width: 100%;
}

.ModalEmotionCard_final .ModalEmotionCard_cards {
  margin-top: 5%;
  height: 90%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.ModalEmotionCard_notfinal {
  height: 100%;
  width: 100%;
}

.ModalEmotionCard_cards {
  height: 80%;
  margin-top: 20%;
}

.ModalEmotionCard_card_notfinal {
  height: 90%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex: auto;
  transition: transform 0.5s;
}

.ModalEmotionCard_card_notfinal:hover {
  transform: scale(1.1);
}

.ModalEmotionCard_card_back_no-description {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.TrickyHead img {
  height: 45%;
  top: 25%;
}

.TrickyHeadPrimary img {
  height: 35%;
  width: 100%;
  top: 30%;
}

.ModalEmotionCard_card_final {
  height: 130px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex: auto;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 25px;
}

.ModalEmotionCard_card_title_final {
  text-transform: uppercase;
  font-weight: bold;
}

.ModalEmotionCard_card_title_notfinal {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  font-size: 0.8vw !important;
  width: 55%;
  display: flex;
  display: -webkit-flex;
  color: #fff;
  font-weight: 700 !important;
}

.ModalEmotionCard_card_description {
  font-size: 0.65vw !important;
  width: 55%;
  text-align: left !important;
  margin-top: 0px;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  font-weight: 500 !important;
}

.ModalEmotionCard_card_face_1,
.ModalEmotionCard_card_face_26,
.ModalEmotionCard_card_face_33,
.ModalEmotionCard_card_face_35,
.ModalEmotionCard_card_face_29,
.ModalEmotionCard_card_face_49,
.ModalEmotionCard_card_face_53,
.ModalEmotionCard_card_face_59,
.ModalEmotionCard_card_face_62 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}
.ModalEmotionCard_card_face_2,
.ModalEmotionCard_card_face_28,
.ModalEmotionCard_card_face_40,
.ModalEmotionCard_card_face_38,
.ModalEmotionCard_card_face_32,
.ModalEmotionCard_card_face_52,
.ModalEmotionCard_card_face_56,
.ModalEmotionCard_card_face_60,
.ModalEmotionCard_card_face_64 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}
.ModalEmotionCard_card_face_23,
.ModalEmotionCard_card_face_27,
.ModalEmotionCard_card_face_34,
.ModalEmotionCard_card_face_36,
.ModalEmotionCard_card_face_30,
.ModalEmotionCard_card_face_50,
.ModalEmotionCard_card_face_54,
.ModalEmotionCard_card_face_57,
.ModalEmotionCard_card_face_63 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.ModalEmotionCard_card_face_24,
.ModalEmotionCard_card_face_44,
.ModalEmotionCard_card_face_41,
.ModalEmotionCard_card_face_37,
.ModalEmotionCard_card_face_31,
.ModalEmotionCard_card_face_51,
.ModalEmotionCard_card_face_55,
.ModalEmotionCard_card_face_58,
.ModalEmotionCard_card_face_61 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.ModalEmotionCard_card_face_25,
.ModalEmotionCard_card_face_45,
.ModalEmotionCard_card_face_46,
.ModalEmotionCard_card_face_47,
.ModalEmotionCard_card_face_48 {
  background-image: url('../../../styles/img/emotions/emotion_cards_primary.svg');
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  background-position: center;
}

.ModalEmotionCard_card_face_25 .ModalEmotionCard_card_description,
.ModalEmotionCard_card_face_45 .ModalEmotionCard_card_description {
  color: #ffffff !important;
}

.ModalEmotionCard_cards_primary {
  height: 40%;
}
.ModalEmotionCard_cards_secondary {
  display: flex;
  display: -webkit-flex;
  height: 50%;
}

.ModalEmotionCard_card_back_not_found {
  background-image: url('../../../styles/img/emotions/emotion_cards_primary.svg');
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  background-position: center;
  height: 100%;
}
.ModalEmotionCard_card_back_1,
.ModalEmotionCard_card_back_2,
.ModalEmotionCard_card_back_3,
.ModalEmotionCard_card_back_4 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
}

/* MODALFOUNDCARD */

#Layer_1 {
  height: 50%;
  margin: auto;
}

.ModalPrimaryCard-Sedondary {
  position: relative;
}

.cardSideBack {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 80%;
  position: absolute;
  top: 10%;
  left: 32.8%;
  width: 34.2%;
  backface-visibility: hidden;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  animation: flipBack 2s ease-out forwards;
  background-image: linear-gradient(to right bottom, #2a3585, #9b3b83);
}

.cardSideBackPrimary {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 80%;
  top: 10%;
  left: 32.8%;
  width: 34.2%;
  backface-visibility: hidden;
  overflow: hidden;
  /* animation: flipBack 2s ease-out forwards; */
  background-image: linear-gradient(to right bottom, #2a3585, #9b3b83);
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.cardSideFront {
  perspective-origin: 50% 30%;
  height: 80%;
  width: 35%;
  transform: rotateY(-180deg);
  animation: flipFront 2s ease-out forwards;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cardFoundTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5vw;
  width: 75%;
  color: #fff;
  font-weight: 700 !important;
}

.cardFoundTitleSmall {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  font-size: 2vw;
  width: 75%;
  color: #fff;
  font-weight: 700 !important;
}

.cardFoundText {
  width: 75%;
  margin-top: 0px;
  font-size: 1.3vw;
  color: #fff;
  font-weight: 500 !important;
}

.cardAway {
  animation: getAway 1s ease forwards;
  perspective-origin: 50% 30%;
  height: 80%;
  width: 35%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  position: absolute;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@keyframes getAway {
  from {
    opacity: 1;
    right: 32.5%;
    top: 10%;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.13);
    right: 0%;
    top: -35%;
  }
}

@keyframes flipFront {
  from {
    transform: rotateY(-180deg) scale(0.1);
  }
  50% {
    transform: rotateY(-180deg) scale(1);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes flipBack {
  from {
    transform: rotateY(0) scale(0.1);
  }
  50% {
    transform: rotateY(0) scale(1);
  }
  to {
    transform: rotateY(180deg);
  }
}

/* MODALPRIMARYCARD */

.ModalPrimaryCard {
  height: 90%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  flex: auto;
  width: 100%;
  padding: 5px 10px;
}

.ModalPrimaryCardTitle {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  color: #fff;
  font-size: 1vw !important;
  width: 60%;
  margin-bottom: 10px;
  display: flex;
  display: -webkit-flex;
  font-weight: 700 !important;
}

.ModalPrimaryCardTitle2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.8vw !important;
  font-weight: 700 !important;
}

.ModalPrimaryCardMainEmotion {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5%;
  font-size: 1vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}
.ModalPrimaryCardSecondaryEmotion {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}
.ModalPrimaryCardEmotion {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 10%;
  font-size: 0.8vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}

.ModalPrimaryCardDescription {
  width: 55% !important;
  font-size: 0.7vw !important;
  width: 60% !important;
  text-align: left !important;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  margin-top: 0px;
  font-weight: 500 !important;
}

.ModalEmotionCardPrimary-Switch {
  animation: flipFrontPrimary 1s ease-out forwards;
  perspective-origin: 50% 30%;
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimary_face_1,
.ModalEmotionCardPrimary_face_26,
.ModalEmotionCardPrimary_face_33,
.ModalEmotionCardPrimary_face_35,
.ModalEmotionCardPrimary_face_29,
.ModalEmotionCardPrimary_face_49,
.ModalEmotionCardPrimary_face_53,
.ModalEmotionCardPrimary_face_59,
.ModalEmotionCardPrimary_face_62 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 3%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  animation: bounceCard 2s ease infinite;
}
.ModalEmotionCardPrimary_face_2,
.ModalEmotionCardPrimary_face_28,
.ModalEmotionCardPrimary_face_40,
.ModalEmotionCardPrimary_face_38,
.ModalEmotionCardPrimary_face_32,
.ModalEmotionCardPrimary_face_52,
.ModalEmotionCardPrimary_face_56,
.ModalEmotionCardPrimary_face_60,
.ModalEmotionCardPrimary_face_64 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 26%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  animation: bounceCard 2s ease infinite;
}
.ModalEmotionCardPrimary_face_23,
.ModalEmotionCardPrimary_face_27,
.ModalEmotionCardPrimary_face_34,
.ModalEmotionCardPrimary_face_36,
.ModalEmotionCardPrimary_face_30,
.ModalEmotionCardPrimary_face_50,
.ModalEmotionCardPrimary_face_54,
.ModalEmotionCardPrimary_face_57,
.ModalEmotionCardPrimary_face_63 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 26%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  animation: bounceCard 2s ease infinite;
}

.ModalEmotionCardPrimary_face_24,
.ModalEmotionCardPrimary_face_44,
.ModalEmotionCardPrimary_face_41,
.ModalEmotionCardPrimary_face_37,
.ModalEmotionCardPrimary_face_31,
.ModalEmotionCardPrimary_face_51,
.ModalEmotionCardPrimary_face_55,
.ModalEmotionCardPrimary_face_58,
.ModalEmotionCardPrimary_face_61 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 3%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  animation: bounceCard 2s ease infinite;
}

@keyframes bounceCard {
  from {
    transform: rotateZ(0deg);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  45% {
    transform: rotateZ(1deg);
  }
  55% {
    transform: rotateZ(-1deg);
  }
  70% {
    transform: rotateZ(1deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}

.ModalEmotionCardPrimary_face_25,
.ModalEmotionCardPrimary_face_45,
.ModalEmotionCardPrimary_face_46,
.ModalEmotionCardPrimary_face_47,
.ModalEmotionCardPrimary_face_48 {
  background-image: url('../../../styles/img/emotions/emotion_cards_primary.svg');
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  height: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
  background-size: contain;
}

.ModalEmotionCardPrimaryReversing_face_1,
.ModalEmotionCardPrimaryReversing_face_26,
.ModalEmotionCardPrimaryReversing_face_33,
.ModalEmotionCardPrimaryReversing_face_35,
.ModalEmotionCardPrimaryReversing_face_29,
.ModalEmotionCardPrimaryReversing_face_49,
.ModalEmotionCardPrimaryReversing_face_53,
.ModalEmotionCardPrimaryReversing_face_59,
.ModalEmotionCardPrimaryReversing_face_62 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  animation: flipFrontPrimary 1s ease-out forwards;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 3%;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.ModalEmotionCardPrimaryReversing_face_2,
.ModalEmotionCardPrimaryReversing_face_28,
.ModalEmotionCardPrimaryReversing_face_40,
.ModalEmotionCardPrimaryReversing_face_38,
.ModalEmotionCardPrimaryReversing_face_32,
.ModalEmotionCardPrimaryReversing_face_52,
.ModalEmotionCardPrimaryReversing_face_56,
.ModalEmotionCardPrimaryReversing_face_60,
.ModalEmotionCardPrimaryReversing_face_64 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 26%;
  z-index: 2;
  background-position: center;
  animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversing_face_23,
.ModalEmotionCardPrimaryReversing_face_27,
.ModalEmotionCardPrimaryReversing_face_34,
.ModalEmotionCardPrimaryReversing_face_36,
.ModalEmotionCardPrimaryReversing_face_30,
.ModalEmotionCardPrimaryReversing_face_50,
.ModalEmotionCardPrimaryReversing_face_54,
.ModalEmotionCardPrimaryReversing_face_57,
.ModalEmotionCardPrimaryReversing_face_63 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 26%;
  z-index: 2;
  background-position: center;
  animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.ModalEmotionCardPrimaryReversing_face_24,
.ModalEmotionCardPrimaryReversing_face_44,
.ModalEmotionCardPrimaryReversing_face_41,
.ModalEmotionCardPrimaryReversing_face_37,
.ModalEmotionCardPrimaryReversing_face_31,
.ModalEmotionCardPrimaryReversing_face_51,
.ModalEmotionCardPrimaryReversing_face_55,
.ModalEmotionCardPrimaryReversing_face_58,
.ModalEmotionCardPrimaryReversing_face_61 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_front.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 3%;
  z-index: 2;
  background-position: center;
  animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_1,
.ModalEmotionCardPrimaryReversed_face_26,
.ModalEmotionCardPrimaryReversed_face_33,
.ModalEmotionCardPrimaryReversed_face_35,
.ModalEmotionCardPrimaryReversed_face_29,
.ModalEmotionCardPrimaryReversed_face_49,
.ModalEmotionCardPrimaryReversed_face_53,
.ModalEmotionCardPrimaryReversed_face_59,
.ModalEmotionCardPrimaryReversed_face_62 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  left: 3%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_2,
.ModalEmotionCardPrimaryReversed_face_28,
.ModalEmotionCardPrimaryReversed_face_40,
.ModalEmotionCardPrimaryReversed_face_38,
.ModalEmotionCardPrimaryReversed_face_32,
.ModalEmotionCardPrimaryReversed_face_52,
.ModalEmotionCardPrimaryReversed_face_56,
.ModalEmotionCardPrimaryReversed_face_60,
.ModalEmotionCardPrimaryReversed_face_64 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  left: 26%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_23,
.ModalEmotionCardPrimaryReversed_face_27,
.ModalEmotionCardPrimaryReversed_face_34,
.ModalEmotionCardPrimaryReversed_face_36,
.ModalEmotionCardPrimaryReversed_face_30,
.ModalEmotionCardPrimaryReversed_face_50,
.ModalEmotionCardPrimaryReversed_face_54,
.ModalEmotionCardPrimaryReversed_face_57,
.ModalEmotionCardPrimaryReversed_face_63 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  right: 26%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.ModalEmotionCardPrimaryReversed_face_24,
.ModalEmotionCardPrimaryReversed_face_44,
.ModalEmotionCardPrimaryReversed_face_41,
.ModalEmotionCardPrimaryReversed_face_37,
.ModalEmotionCardPrimaryReversed_face_31,
.ModalEmotionCardPrimaryReversed_face_51,
.ModalEmotionCardPrimaryReversed_face_55,
.ModalEmotionCardPrimaryReversed_face_58,
.ModalEmotionCardPrimaryReversed_face_61 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  right: 3%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_1,
.ModalEmotionCardPrimaryReversedOn_face_26,
.ModalEmotionCardPrimaryReversedOn_face_33,
.ModalEmotionCardPrimaryReversedOn_face_35,
.ModalEmotionCardPrimaryReversedOn_face_29,
.ModalEmotionCardPrimaryReversedOn_face_49,
.ModalEmotionCardPrimaryReversedOn_face_53,
.ModalEmotionCardPrimaryReversedOn_face_59,
.ModalEmotionCardPrimaryReversedOn_face_62 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  left: 3%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_2,
.ModalEmotionCardPrimaryReversedOn_face_28,
.ModalEmotionCardPrimaryReversedOn_face_40,
.ModalEmotionCardPrimaryReversedOn_face_38,
.ModalEmotionCardPrimaryReversedOn_face_32,
.ModalEmotionCardPrimaryReversedOn_face_52,
.ModalEmotionCardPrimaryReversedOn_face_56,
.ModalEmotionCardPrimaryReversedOn_face_60,
.ModalEmotionCardPrimaryReversedOn_face_64 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  left: 26%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_23,
.ModalEmotionCardPrimaryReversedOn_face_27,
.ModalEmotionCardPrimaryReversedOn_face_34,
.ModalEmotionCardPrimaryReversedOn_face_36,
.ModalEmotionCardPrimaryReversedOn_face_30,
.ModalEmotionCardPrimaryReversedOn_face_50,
.ModalEmotionCardPrimaryReversedOn_face_54,
.ModalEmotionCardPrimaryReversedOn_face_57,
.ModalEmotionCardPrimaryReversedOn_face_63 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  right: 26%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_24,
.ModalEmotionCardPrimaryReversedOn_face_44,
.ModalEmotionCardPrimaryReversedOn_face_41,
.ModalEmotionCardPrimaryReversedOn_face_37,
.ModalEmotionCardPrimaryReversedOn_face_31,
.ModalEmotionCardPrimaryReversedOn_face_51,
.ModalEmotionCardPrimaryReversedOn_face_55,
.ModalEmotionCardPrimaryReversedOn_face_58,
.ModalEmotionCardPrimaryReversedOn_face_61 {
  background-image: url('../../../styles/img/emotions/emotion_cards_secondary_back.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  right: 3%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.path_container {
  position: absolute;
  width: 70%;
  left: 15%;
}

.path {
  stroke-linecap: round;
  fill: transparent;
  stroke: salmon;
  stroke-width: 6;
  stroke-linecap: round;
}

.path2 {
  opacity: 0.2;
}

.line1opacity {
  stroke-dasharray: 346;
  stroke-dashoffset: 346;
  stroke-dashoffset: 0;
}
.line2opacity {
  stroke-dasharray: 148;
  stroke-dashoffset: 148;
  stroke-dashoffset: 0;
}
.line3opacity {
  stroke-dasharray: 148;
  stroke-dashoffset: 148;
  stroke-dashoffset: 0;
}
.line4opacity {
  stroke-dasharray: 346;
  stroke-dashoffset: 346;
  stroke-dashoffset: 0;
}

.line1 {
  stroke-dasharray: 422;
  stroke-dashoffset: 422;
  animation: dash 1s ease-in-out forwards;
}

.line2 {
  stroke-dasharray: 244;
  stroke-dashoffset: 244;
  animation: dash 1s ease-in-out forwards;
}

.line3 {
  stroke-dasharray: 244;
  stroke-dashoffset: 244;
  animation: dash 1s ease-in-out forwards;
}

.line4 {
  stroke-dasharray: 422;
  stroke-dashoffset: 422;
  animation: dash 1s ease-in-out forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes flipFrontPrimary {
  from {
    transform: rotateY(0deg);
  }
  50% {
    z-index: 2;
  }
  to {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
}

@keyframes flipBackPrimary {
  from {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
  80% {
    z-index: 3;
  }
  to {
    transform: rotateY(0);
    visibility: visible;
    z-index: 3;
  }
}
