/* OFFICE */

.clock-office img {
  position: absolute;
  z-index: 2;
  height: 8%;
  top: 15.5%;
  left: 43%;
}

.hat-office img {
  position: absolute;
  z-index: 2;
  height: 15%;
  top: 27%;
  left: 25%;
}

.coatrack-office img {
  position: absolute;
  z-index: 1;
  height: 38%;
  top: 30%;
  left: 24.5%;
}

.trash-office img {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 80.5%;
  left: 80%;
}

.desktoplamp-office img {
  position: absolute;
  z-index: 3;
  height: 21.5%;
  top: 50.5%;
  left: 33.3%;
}

.desktoppapers-office img {
  position: absolute;
  z-index: 3;
  width: 17%;
  top: 70.2%;
  left: 33.3%;
}

.desktoptoplamp-office img {
  position: absolute;
  z-index: 2;
  width: 31%;
  top: 3.2%;
  left: 32.7%;
}

.desktopdoor-office img {
  position: absolute;
  z-index: 2;
  height: 66%;
  top: 13.2%;
  right: 3.5%;
}

.desktopcupboard-office img {
  position: absolute;
  z-index: 2;
  height: 70%;
  top: 23.2%;
  left: -0.5%;
}

.desktopchair-office img {
  position: absolute;
  z-index: 1;
  height: 44%;
  top: 52.2%;
  left: 43%;
}

.desktoplockers-office img {
  position: absolute;
  z-index: 0;
  width: 32%;
  top: 23.6%;
  left: 27.2%;
}

.desktopdesktop-office img {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  width: 55%;
  bottom: 0;
  left: 19.5%;
}
