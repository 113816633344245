.Tunnel-Header-Container {
  height: 6vh;
  /* border-bottom: 1px solid green; */
  display: flex;
  background: #ffffff;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* margin: 0px 0px 20px 0px; */
}

.Tunnel-Header-Container-Logo {
  width: 20%;
}

.Tunnel-Header-Container-Logo img {
  width: 35%;
}

.Tunnel-Header-Container-Navbar {
  width: 50%;
  /* height: 4vh; */
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  margin: 20px 0px 40px 0px;
  position: relative;
}

.Tunnel-Header-Line-Grey {
  position: absolute;
  top: 62%;
  width: 78%;
  height: 10%;
  background-color: #c5c5c5;
  z-index: 0;
}

.Tunnel-Header-Line-Purple {
  position: absolute;
  top: 62%;
  left: 10%;
  height: 10%;
  background-color: #7103ec;
  z-index: 1;
  transition: width 1s;
}

.Tunnel-Header-Action-Container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
}

.fa-check-circle:before {
  font-size: 34px;
  color: #7103ec;
  z-index: 10;
}

.Tunnel-Header-Action-Pastille-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.Tunnel-Header-Action-Pastille-Container-Title-Done {
  color: rgba(113, 3, 236, 0.4);
  /* color: #7103ec; */
  font-size: 14px;
  font-weight: bold;
}

.Tunnel-Header-Action-Pastille-Container-Title {
  font-size: 14px;
  font-weight: bold;
}

.Tunnel-Header-Action-Pastille-1,
.Tunnel-Header-Action-Pastille-2,
.Tunnel-Header-Action-Pastille-3,
.Tunnel-Header-Action-Pastille-4,
.Tunnel-Header-Action-Pastille-5 {
  background: #fff;
  border: 3px solid #c5c5c5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  z-index: 2;
  transition: background 1s;
}

.Tunnel-Header-Action-Pastille-1-doing,
.Tunnel-Header-Action-Pastille-2-doing,
.Tunnel-Header-Action-Pastille-3-doing,
.Tunnel-Header-Action-Pastille-4-doing,
.Tunnel-Header-Action-Pastille-5-doing {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  transition: background 1s;
}

.Tunnel-Header-Action-Pastille-1,
.Tunnel-Header-Action-Pastille-2,
.Tunnel-Header-Action-Pastille-3,
.Tunnel-Header-Action-Pastille-4,
.Tunnel-Header-Action-Pastille-5 p {
  align-self: center;
  color: grey;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Header-Action-Pastille-1-done,
.Tunnel-Header-Action-Pastille-2-done,
.Tunnel-Header-Action-Pastille-3-done,
.Tunnel-Header-Action-Pastille-4-done,
.Tunnel-Header-Action-Pastille-5-done p {
  align-self: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Header-Action-Behind-Pastille-1-done,
.Tunnel-Header-Action-Behind-Pastille-2-done,
.Tunnel-Header-Action-Behind-Pastille-3-done,
.Tunnel-Header-Action-Behind-Pastille-4-done,
.Tunnel-Header-Action-Behind-Pastille-5-done {
  background: #fff;
  border: 3px solid #c5c5c5;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 38%;
  z-index: -1;
}

.Tunnel-Header-User-Choice {
  position: absolute;
  top: 100%;
}

.Tunnel-Header-User-Choice span {
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  /* line-height: 15px; */
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}
