.ZoomDrawer {
  position: relative;
  width: 100%;
  height: 100%;
}

.ZoomDrawerKnife {
  position: absolute;
  background: url('../../styles/img/Game/BUREAU-4_tirroir-1_CROPPED-01.svg')
    no-repeat;
  background-position: center;
  width: 100%;
  height: 70%;
}

.ZoomDrawerKnife img {
  top: 8%;
  left: 38%;
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}

.drawer img {
  position: absolute;
  z-index: 3;
  height: 11.5%;
  top: 79.4%;
  left: 54.2%;
}

.Drawer-Open {
  height: 150px;
  position: relative;
  opacity: 60%;
  margin-top: 60px;
}

.Drawer-Knife img {
  width: 100%;
  z-index: 1;
}

#button1-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

#button1-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

#button1-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#button1-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

#button2-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

#button2-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

#button2-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#button2-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

#button3-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

#button3-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

#button3-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#button3-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.zoom-drawer {
  position: relative;
  background: url('../../styles/img/Game/BUREAU-4_tirroir-1_CROPPED-01.svg')
    no-repeat;
  background-position: center;
  width: 350px;
  height: 150px;
  margin-bottom: 15px;
}

#zoom-button1-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button1-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button1-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button1-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button2-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button2-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button2-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button2-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button3-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button3-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button3-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button3-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  cursor: pointer;
}
