@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #525252;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.App {
  height: 100%;
}

:focus {
  outline: 0;
  border: 0;
}

.container {
  width: 900px;
  margin: auto;
}

/* GAME */
.Game {
  display: flex;
  display: -webkit-flex;
  background: #2d1837;
}

.GameWindow {
  height: 100vh;
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  align-items: center;
  -webkit-align-items: center;
  background: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
}

.GameWindow-DemoMulti {
  height: 100vh;
  background: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
}

.GameWindow-Boards {
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  align-items: center;
  -webkit-align-items: center;
  height: 100vh;
}

/* BUTTONS */

.ButtonAction {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin: 15px auto;
  font-weight: bold;

  background-size: 200%;
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Action {
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Contact {
  background-image: linear-gradient(90deg, #792887, #cf1c83, #d93a5b, #e76322);
  background-position: left;
  border: 1px solid #454545;
  box-shadow: 0px 0px 5px #454545;
  transition: background-position ease-out 0.5s;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Action:hover,
.Contact:hover {
  background-position: right;
}

.Return {
  color: #454545;
  border: 2px solid #454545;
}

.Cancel {
  background-color: #000;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}
.Cancel:hover {
  background-color: darkslategray;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Hide {
  display: none;
}

.Show {
  display: block;
  width: 100%;
}

.Buttons {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}

.Buttons button {
  margin: 10px;
}

/* CURSOR CLICKED */

.cursor {
  width: 0;
  height: 0;
  border: 2px solid transparent;
  background-color: transparent;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* opacity: 1; */
}

.cursor-clicked {
  width: 0rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 1s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  /* opacity: 0; */
}

.cursor p {
  font-size: 0px;
  color: #fff;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  /* opacity: 1; */
  position: absolute;
}

.cursor-clicked p {
  font-size: 40px;
  color: transparent;
  visibility: visible;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: fade 1s;
          animation: fade 1s;
}

@-webkit-keyframes fade {
  0% {
    color: transparent;
    font-size: 0px;
    transform: translateY(0%);
  }
  20% {
    color: #fff;
  }
  80% {
    color: #fff;
  }
  100% {
    font-size: 40px;
    transform: translateY(-100%);
    color: transparent;
  }
}

@keyframes fade {
  0% {
    color: transparent;
    font-size: 0px;
    transform: translateY(0%);
  }
  20% {
    color: #fff;
  }
  80% {
    color: #fff;
  }
  100% {
    font-size: 40px;
    transform: translateY(-100%);
    color: transparent;
  }
}

.DigitalRoomVideo {
  position: relative;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DigitalRoomVideo-Grey {
  width: 100%;
  background: #e0e0e0ff;
}

.DigitalRoomVideo-Title {
  text-align: center;
  font-family: 'Montserrat', Tahoma, Verdana, sans-serif;
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.DigitalRoomVideo-Title h2 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem !important;
  margin: 30px auto;
}

.DigitalRoomVideo-Description {
  margin-bottom: 20px;
}

.DigitalRoomVideo-Description h3,
.DigitalRoomVideo-Description p {
  color: #525252;
  text-align: center;
}

.DigitalRoomVideo-Description h3 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  margin: 0px auto;
}

.DigitalRoomVideo-Description p {
  margin-top: 10px;
  font-size: 1rem;
}

.DigitalRoomVideo-Video {
  margin: 20px auto;
}

.DigitalRoomVideo-Video video {
  width: 900px;
  height: 438px;
  object-fit: cover;
}

.DigitalRoomVideo-VideoFrame {
  position: relative;
}

.fa-volume-up {
  position: absolute;
  bottom: 3%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

.fa-volume-mute {
  position: absolute;
  bottom: 3%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

.DigitalRoomVideo-Button {
  margin: 0px auto 50px auto;
}

.DigitalRoomVideo-Button button {
  color: #fff;
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-size: 200%;
  background-position: left;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 -40px -40px -40px;
  transition: background-position ease-out 0.5s;
  cursor: pointer;
}

.DigitalRoomVideo-Button button:hover {
  background-position: right;
}

/* HOW IT WORKS */

.DigitalRoomVideo-Howitworks {
  display: flex;
  margin-bottom: 50px;
}

.DigitalRoom-AllSteps {
  display: flex;
  flex-direction: column;
}

.DigitalRoom-Text h3 {
  color: #e3505d;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.DigitalRoom-Text p {
  margin-top: 0px;
  padding-left: 30px;
}

.DigitalRoom-Step {
  display: flex;
}

.DigitalRoomVideo-DivNumber {
  margin-right: 20px;
}

.DigitalRoomVideo-AllNumbers {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 30px;
}

.DigitalRoomVideo-Number {
  margin-top: 10px;
  color: #e3505d;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: 'Montserrat', Tahoma, Verdana, sans-serif;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #e3505d 4px solid;
  background-color: #fff;
  border-radius: 50%;
}

/* ABOUT */

.DigitalRoomVideo-AllArguments {
  display: flex;
  margin-bottom: 50px;
}

.DigitalRoomVideo-Argument {
  margin-top: 30px;
  width: 33%;
}

.DigitalRoomVideo-Argument h3,
.DigitalRoomVideo-Argument p {
  color: #525252;
  text-align: center;
}

.DigitalRoomVideo-Argument span {
  text-align: center;
  font-size: 4rem;
  color: #525252;
}

.DigitalRoomVideo-Argument h3 {
  margin-bottom: 0px;
  text-transform: uppercase;
}

.DigitalRoomVideo-Argument p {
  margin-top: 10px;
}

.DigitalRoomVideo-Spans {
  display: flex;
  justify-content: center;
}

.DigitalRoomVideo-Argument:hover h3 {
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.DigitalRoomVideo-Argument:hover span {
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* THEY TRUST US */

.DigitalRoomVideo-Partner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.DigitalRoomVideo-Partner img {
  width: 200px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.DigitalRoomVideo-Partner img:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

@media screen and (max-width: 1300px) {
  .DigitalRoomVideo-Video video {
    width: 700px;
    height: 342px;
  }
}

@media screen and (max-width: 990px) {
  .DigitalRoomVideo-Video video {
    width: 600px;
    height: 292px;
  }
}

@media screen and (max-width: 750px) {
  .DigitalRoomVideo-Video video {
    width: 270px;
    height: 131px;
  }
}

.Tunnel-Container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Tunnel-Form-Choice {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 70%;
  height: 30%;
  margin-top: 50px;
  position: relative;
}

.Tunnel-Form-Choice-Action {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 30%;
  margin-top: 50px;
  position: relative;
}

.Tunnel-Form-Choice-Univers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 30%;
  margin-top: 50px;
}

.Tunnel-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 75vh;
}

.Tunnel-Form h3 {
  margin-top: 50px;
  font-size: 26px;
}

.fa-check-circle:before {
  font-size: 35px;
  color: red;
}

.Tunnel-Choice-Container-1,
.Tunnel-Choice-Container-2,
.Tunnel-Choice-Container-3,
.Tunnel-Choice-Container-4,
.Tunnel-Choice-Container-5,
.Tunnel-Choice-Container-6 {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  transition: box-shadow 0.5s;
}

.Tunnel-Choice-Container-1 p,
.Tunnel-Choice-Container-2 p,
.Tunnel-Choice-Container-3 p,
.Tunnel-Choice-Container-4 p,
.Tunnel-Choice-Container-5 p,
.Tunnel-Choice-Container-6 p,
.Tunnel-Choice-Container-1-choosen p,
.Tunnel-Choice-Container-2-choosen p,
.Tunnel-Choice-Container-3-choosen p,
.Tunnel-Choice-Container-4-choosen p,
.Tunnel-Choice-Container-5-choosen p,
.Tunnel-Choice-Container-6-choosen p {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Choice-Container-1:hover,
.Tunnel-Choice-Container-2:hover,
.Tunnel-Choice-Container-3:hover,
.Tunnel-Choice-Container-4:hover,
.Tunnel-Choice-Container-5:hover,
.Tunnel-Choice-Container-6:hover {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.Tunnel-Choice-Container-1-choosen,
.Tunnel-Choice-Container-2-choosen,
.Tunnel-Choice-Container-3-choosen,
.Tunnel-Choice-Container-4-choosen,
.Tunnel-Choice-Container-5-choosen,
.Tunnel-Choice-Container-6-choosen {
  background: #fff;
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 2px solid rgba(113, 3, 236, 0.7);
  border-radius: 8px;
  text-align: center;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.Tunnel-Choice-Check {
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 16px;
  right: 16px;
}

.Tunnel-Choice-Check-Good {
  position: absolute;
  top: 16px;
  right: 16px;
}

.Tunnel-Form-Footer {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 10vh;
}

.Back-Button {
  background: #fff;
  text-align: center;
  color: rgba(113, 3, 236, 0.5);
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.Back-Button:hover {
  background: #fff;
  text-align: center;
  color: rgba(113, 3, 236, 1);
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
  border: 2px solid rgba(113, 3, 236, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.Next-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
}

.Tunnel-Choice-Infos {
  display: flex;
  position: absolute;
  top: -30px;
  width: 95vw;
  height: 60vh;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  z-index: 5;
}

.Tunnel-Choice-Infos-Action {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  width: 87%;
  height: 220px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Tunnel-Choice-Infos-Action h3 {
  margin: 15px;
}

.Tunnel-Choice-Infos-Action span {
  line-height: 22px;
}

.Tunnel-Choice-Infos-Action div {
  margin: 5px 15px 5px 15px;
}

.Tunnel-Choice-Infos-Left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
  padding: 10px 10px 10px 30px;
}

.Tunnel-Choice-Infos-Left-Objectif-Container {
  /* margin: 0px 0px 40px 0px; */
}

.Tunnel-Choice-Infos-Left-Duration-Container {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-top: 40px;
}

.Tunnel-Choice-Infos-Left-Duration-Container p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.Tunnel-Choice-Infos-Right-Big-Image {
  background-image: url(/static/media/georg-arthur-pflueger-TeWwYARfcM4-unsplash.65faca7a.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 500px;
  height: 250px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image-2 {
  background-image: url(/static/media/antevasin-nguyen-ccaQyDwMCZ0-unsplash.b0ee572e.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 230px;
  height: 220px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image-1 {
  background-image: url(/static/media/matteo-vistocco-u_CvU45FLH8-unsplash.6d9b174b.jpg);
  background-repeat: no-repeat;
  background-position: 30% center;
  width: 230px;
  height: 220px;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-self: center;
  margin: 20px;
}

.Tunnel-Choice-Infos-Right-Small-Image {
  display: flex;
  justify-content: center;
}

.Tunnel-Choice-Infos-Button {
  position: absolute;
  top: 2%;
  right: 1%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Tunnel-Choice-Infos-Button p {
  width: 12px;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.3);
}

.Tunnel-Choice-Infos-Left-Duration-Container span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.Tunnel-Choice-Infos-Left-Objectif-Container h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin: 0px 0px 10px 0px;
}

.Tunnel-Choice-Infos-Left-Objectif {
  display: flex;
}

.Tunnel-Choice-Infos-Left-Objectif p {
  margin: 5px 5px 5px 0px;
  width: 40%;
}

.Tunnel-Choice-Infos-Left-Title h1 {
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  width: 70%;
  margin: 20px 0px;
}

.Tunnel-Choice-Infos-Left-Title span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.Tunnel-Choice-Infos-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.Tunnel-Choice-Univers-Img img {
  align-self: center;
  width: 60%;
}

.Tunnel-Choice-Univers-Img-ETI {
  align-self: center;
  width: 50%;
}

.Tunnel-Choice-Univers-Title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 5px;
}

.Tunnel-Form-Recap {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.Tunnel-Recap-Container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  transition: 2s;
}

.Tunnel-Contact-Container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.Tunnel-Recap-Global h3 {
  margin: 50px 0px 0px 0px !important;
  text-align: center;
  font-size: 26px;
}

.Tunnel-Recap-Global p {
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}

.Tunnel-Contact-Global {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px;
  position: relative;
}

.Tunnel-Recap-Global {
  margin-top: 20px;
}

.Tunnel-Contact-Form {
  display: flex;
  flex-direction: column;
  padding: 53px;
}

.Tunnel-Contact-Infos-Text {
  text-align: center;
}

.Tunnel-Contact-Infos-Text p {
  margin-top: 5px;
  font-size: 12px;
}

.Tunnel-Contact-Infos-Text h3 {
  margin-bottom: 5px;
}

.Tunnel-Contact-Form h3 {
  margin: 20px 20px 20px 10px;
}

.Tunnel-Contact-Form label {
  padding: 10px 10px;
  margin: 20px 20px 20px 0px;
  width: 20%;
}

.Tunnel-Contact-Form input {
  padding: 10px 10px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #d6d1d5;
  width: 60%;
  margin: 20px 0px 20px 20px;
}

.Tunnel-Contact-Form input:hover,
.Tunnel-Contact-Form input:focus {
  border: 1px solid #7103ec;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #7103ec !important;
}

.Tunnel-Contact-Credential {
  display: flex;
  align-items: center;
  width: 99.5%;
  height: 14%;
  border-top: 1px solid #c2c8ff;
  border-bottom: 1px solid #c2c8ff;
  background: #f5f6ff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  bottom: 0%;
}
.Tunnel-Contact-Credential input {
  margin: 20px 20px 20px 10px;
  padding: 0px;
  width: 10%;
}

.Tunnel-Contact-Error {
  font-size: 10px;
  color: red;
  margin: 18px 20px 0px 14px;
}

.Tunnel-Contact-Credential span {
  font-size: 13px;
  margin: 10px;
}

.Tunnel-Contact-Input {
  display: flex;
  justify-content: space-around;
}

.Send-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
  position: absolute;
  top: 115%;
  left: 115%;
}

.Tunnel-Recap-Choice-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-1-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 2s;
}

.Tunnel-Recap-Choice-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-2-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 3s;
}

.Tunnel-Recap-Choice-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-3-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 4s;
}

.Tunnel-Recap-Choice-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 0;
}

.Tunnel-Recap-Choice-4-transition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  opacity: 1;
  transition: opacity 2s 5s;
}

.Tunnel-Recap-Choice-1-transition span,
.Tunnel-Recap-Choice-2-transition span,
.Tunnel-Recap-Choice-3-transition span,
.Tunnel-Recap-Choice-4-transition span {
  font-size: 16px;
  font-weight: bold;
}

.Tunnel-Recap-Choice-1-transition p,
.Tunnel-Recap-Choice-2-transition p,
.Tunnel-Recap-Choice-3-transition p,
.Tunnel-Recap-Choice-4-transition p {
  font-weight: bold;
  font-size: 12px !important;
  color: #7103ec;
  text-align: left;
}

.Tunnel-Recap-Pastille-Container {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.Tunnel-Recap-Pastille-1 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-1-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 2s;
}

.Tunnel-Recap-Pastille-2 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-2-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 3s;
}

.Tunnel-Recap-Pastille-3 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-3-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 4s;
}

.Tunnel-Recap-Pastille-4 {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
}

.Tunnel-Recap-Pastille-4-transition {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s 5s;
}

.Tunnel-Recap-Pastille-1-transition p,
.Tunnel-Recap-Pastille-2-transition p,
.Tunnel-Recap-Pastille-3-transition p,
.Tunnel-Recap-Pastille-4-transition p {
  margin: 12px 0px !important;
}

.Tunnel-Contact-InfoSent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Generate-Button {
  background: #7103ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid rgba(113, 3, 236, 0.5);
  margin: 20px;
  width: 50%;
}

/* .Tunnel-Recap-Hide-1,
.Tunnel-Recap-Hide-2,
.Tunnel-Recap-Hide-3,
.Tunnel-Recap-Hide-4 {
  width: 20%;
} */

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
}

.Tunnel-Leave {
  position: absolute;
  background-color: #fff;
  opacity: 75%;
  -webkit-backdrop-filter: blur(40em);
          backdrop-filter: blur(40em);
  width: 100%;
  height: 97vh;
  top: 6%;
  z-index: 50;
  display: flex;
  justify-content: center;
}

.Tunnel-Leave-Container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.Tunnel-Leave-Button {
  display: flex;
}

.Tunnel-Choice-Container-Desc {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.Tunnel-Header-Container {
  height: 6vh;
  /* border-bottom: 1px solid green; */
  display: flex;
  background: #ffffff;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* margin: 0px 0px 20px 0px; */
}

.Tunnel-Header-Container-Logo {
  width: 20%;
}

.Tunnel-Header-Container-Logo img {
  width: 35%;
}

.Tunnel-Header-Container-Navbar {
  width: 50%;
  /* height: 4vh; */
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  margin: 20px 0px 40px 0px;
  position: relative;
}

.Tunnel-Header-Line-Grey {
  position: absolute;
  top: 62%;
  width: 78%;
  height: 10%;
  background-color: #c5c5c5;
  z-index: 0;
}

.Tunnel-Header-Line-Purple {
  position: absolute;
  top: 62%;
  left: 10%;
  height: 10%;
  background-color: #7103ec;
  z-index: 1;
  transition: width 1s;
}

.Tunnel-Header-Action-Container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
}

.fa-check-circle:before {
  font-size: 34px;
  color: #7103ec;
  z-index: 10;
}

.Tunnel-Header-Action-Pastille-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.Tunnel-Header-Action-Pastille-Container-Title-Done {
  color: rgba(113, 3, 236, 0.4);
  /* color: #7103ec; */
  font-size: 14px;
  font-weight: bold;
}

.Tunnel-Header-Action-Pastille-Container-Title {
  font-size: 14px;
  font-weight: bold;
}

.Tunnel-Header-Action-Pastille-1,
.Tunnel-Header-Action-Pastille-2,
.Tunnel-Header-Action-Pastille-3,
.Tunnel-Header-Action-Pastille-4,
.Tunnel-Header-Action-Pastille-5 {
  background: #fff;
  border: 3px solid #c5c5c5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  z-index: 2;
  transition: background 1s;
}

.Tunnel-Header-Action-Pastille-1-doing,
.Tunnel-Header-Action-Pastille-2-doing,
.Tunnel-Header-Action-Pastille-3-doing,
.Tunnel-Header-Action-Pastille-4-doing,
.Tunnel-Header-Action-Pastille-5-doing {
  background: #7103ec;
  border: 3px solid #7103ec;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  transition: background 1s;
}

.Tunnel-Header-Action-Pastille-1,
.Tunnel-Header-Action-Pastille-2,
.Tunnel-Header-Action-Pastille-3,
.Tunnel-Header-Action-Pastille-4,
.Tunnel-Header-Action-Pastille-5 p {
  align-self: center;
  color: grey;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Header-Action-Pastille-1-done,
.Tunnel-Header-Action-Pastille-2-done,
.Tunnel-Header-Action-Pastille-3-done,
.Tunnel-Header-Action-Pastille-4-done,
.Tunnel-Header-Action-Pastille-5-done p {
  align-self: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.Tunnel-Header-Action-Behind-Pastille-1-done,
.Tunnel-Header-Action-Behind-Pastille-2-done,
.Tunnel-Header-Action-Behind-Pastille-3-done,
.Tunnel-Header-Action-Behind-Pastille-4-done,
.Tunnel-Header-Action-Behind-Pastille-5-done {
  background: #fff;
  border: 3px solid #c5c5c5;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 38%;
  z-index: -1;
}

.Tunnel-Header-User-Choice {
  position: absolute;
  top: 100%;
}

.Tunnel-Header-User-Choice span {
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  /* line-height: 15px; */
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.Bureau-img {
  position: relative;
  width: 80%;
  z-index: 0;
}

.Bureau-img-div:focus {
  outline: none;
}

.CloseWindow img {
  position: absolute;
  width: 5%;
  top: 5%;
  right: 5%;
}

.power-chord img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 21%;
  top: 72%;
  left: 13%;
}

.power-chord-plugged img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 27%;
  top: 64%;
  left: 13%;
}

.screen img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenPassword img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenEmail img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenSleep img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

/* FOLDER */

#letter {
  height: 80% !important;
}

.folder img {
  position: absolute;
  z-index: 1;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
  cursor: pointer;
}

.hide-folder img {
  position: absolute;
  z-index: 2;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
}

.letterroom img {
  position: absolute;
  -webkit-animation: zoomleft 0.5s linear;
          animation: zoomleft 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: block;
  z-index: 1;
}

.hide-folder {
  -webkit-animation: hidefolder 1s linear;
          animation: hidefolder 1s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes hidefolder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes hidefolder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

/* BOARD */

.board img {
  position: absolute;
  z-index: 0;
  height: 37.4%;
  top: 19.9%;
  left: 66.5%;
  cursor: pointer;
}

.modal-email {
  margin-top: 200px;
  background: #23345a;
  border: 10px solid #000000;
  border-radius: 20px;
  max-width: 1000px;
  min-height: 100px;
  padding: 25px;
  position: relative;
}

.email-container-image img {
  width: 90%;
}

#mug {
  height: 80% !important;
}

.mug img {
  position: absolute;
  z-index: 3;
  height: 8.5%;
  top: 61%;
  left: 41%;
  cursor: pointer;
}

.mug2 img {
  position: absolute;
  z-index: 3;
  height: 6.5%;
  top: 70%;
  left: 77%;
  cursor: pointer;
}

.phone img {
  position: absolute;
  z-index: 4;
  height: 13%;
  top: 64.4%;
  left: 25%;
  cursor: pointer;
}

.mug #smoke {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  -webkit-animation: smoke 2s infinite;
          animation: smoke 2s infinite;
  transform: rotate(18deg);
}
.mug #smoke2 {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke2:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  -webkit-animation: smoke2 5s infinite;
          animation: smoke2 5s infinite;
  transform: rotate(18deg);
}
@-webkit-keyframes smoke {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -20px -25px, -5px -25px, 0px -25px, 0px -25px;
    opacity: 0;
  }
}
@keyframes smoke {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -20px -25px, -5px -25px, 0px -25px, 0px -25px;
    opacity: 0;
  }
}
@-webkit-keyframes smoke2 {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -10px 15px, 5px -15px, 0px 15px, 0px 15px;
    opacity: 0;
  }
}
@keyframes smoke2 {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -10px 15px, 5px -15px, 0px 15px, 0px 15px;
    opacity: 0;
  }
}

/* MODALS */

.modal-body img {
  margin: 50px;
}

.hidden {
  display: none;
}

.Hide-Button {
  background-color: transparent;
  border: none;
  cursor: none;
}

@font-face {
  font-family: 'Urania';
  src: url(/static/media/Urania_Czech.e946d0fd.woff) format('woff');
}

/* MUG */

.Align-Center {
  align-self: center;
}

/* MODALS */

.ModalTricky-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition-duration: 0.5s;
}

.ModalTricky-Background h3 {
  color: #fff;
  margin-bottom: 0px;
}

.ModalTricky-Background-Time {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition-duration: 0.5s;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.ModalTricky-NoClick {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-NoClick2 {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 82;
  transition-duration: 0.5s;
}

.Prevention-Message {
  padding: 20px;
  color: #fff;
}

.ModalTricky-button {
  width: 100%;
}

.ModalTricky-button button {
  position: absolute;
  transform: translateX(-60%);
}

.ModalTrickyObject-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7%;
  left: 20%;
  width: 60%;
  height: 76.3%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Background-Position {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6.8%;
  left: 20.1%;
  width: 59.7%;
  height: 74%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Background2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  height: 70%;
  width: 70%;
}

.ModalTricky-TimeEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 70%;
}

.ModalTricky-Animation {
  width: 247.359px;
  height: 187.219px;
  margin-bottom: 70px;
}

.ModalTricky-Container-Powerchord {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
}

.ModalTricky-WrongObject {
  height: 100%;
  width: 100%;
}

.ModalTricky-WrongObject img {
  -webkit-animation: showwrongitem 0.5s ease;
          animation: showwrongitem 0.5s ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  height: 100%;
}

/* MODAL INTRO */

.ModalTricky-Intro {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Intro-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 60%;
  background: #fff;
  border-radius: 5px;
}

.ModalTricky-Intro-Card {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  text-align: center;
  color: #000;
  padding: 0px 30px;
}

.ModalTricky-Intro-Text {
  display: flex;
  flex-direction: column;
  line-height: 1.3rem;
}

.ModalTricky-Intro-TextGrey {
  color: #999999;
}

.fa-clock {
  color: #999999;
  font-size: 2rem;
}

.ModalTricky-Intro-Img img {
  object-fit: cover;
  height: 400px;
  width: 350px;
}

/* MODAL LETTER */

.opened-letter {
  height: 90% !important;
}

.Letter-Open {
  width: 250%;
  margin-bottom: 1px !important;
}

.Letter-Text {
  width: 165%;
  margin-bottom: 0 !important;
  position: relative;
}

.Letter-Text-Cont {
  position: absolute;
  top: 30%;
  width: 20%;
}

.Letter-Text-Cont p {
  color: #000000;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
  color: #fff;
}

.ModalTricky-p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.ModalObjectTricky-p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.ModalObjectTricky-p p {
  color: #fff;
}

.ModalObjectTricky-p img {
  width: 20%;
  margin-top: 30px;
  margin-bottom: 0;
}

.ModalObjectTricky-p .MugImg {
  width: 80%;
  margin-left: 70px;
}

.ModalObject-Button {
  display: flex;
}

.ModalTricky-Emotion-Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
}

.ModalTricky-Emotion-Card-Text {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(10%);
  width: 100%;
  text-align: center;
}

.ModalTricky-Emotion-Card-Text > p {
  color: #fff;
  width: 80%;
  margin: 2rem auto;
}

.ModalTricky-Emotion-Cards {
  display: flex;
  flex-direction: row;
}

.ModalTricky-Emotion-Cards-P {
  width: 20%;
  color: #000;
  background: #fff;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 5px hsl(0, 90%, 53%), 0 0 0 12px hsl(0, 0%, 100%);
}

.ModalTricky-Emotion-Card h5 {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h4 {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h3 {
  color: #fff;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
}

.button-continue {
  position: absolute;
  bottom: 2%;
}

.Emotion-Card-Container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 5px;
  width: 90%;
  height: 90%;
}

.ModalTricky-Emotion-Card img {
  width: 95%;
  align-self: center;
  margin-bottom: 0px !important;
  position: relative;
}

.EmotionCont {
  display: flex;
}

.Emotion-Card {
  position: relative;
  width: 100%;
}

.Emotion-Card-Title-1 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-1 {
  position: absolute;
  top: 95px;
  left: 42px;
  width: 70%;
}

.Emotion-Card-Title-2 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-2 {
  position: absolute;
  top: 90px;
  left: 42px;
  width: 70%;
}

.ModalTricky-Emotion-Card-Text .fa-check-circle:before {
  margin: 5px 5px 0px 0px !important;
  color: #fff !important;
}

.Hide-Modal {
  display: none !important;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord img {
  width: 100%;
}

.ModalTricky-Computer {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 300px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Success {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid green;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Error {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid red;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Email {
  display: flex;
  padding: 0.5vw;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  background: #23345a;
  border: 0.5vw solid #000000;
  border-radius: 2vw;
}

.ModalTricky-Computer-Email-p {
  background: #fff;
  border-radius: 1vw;
}

.ModalTricky-Computer-Password label {
  color: #fff;
  margin-right: 10px;
}

.ModalTricky-Computer-Password .Error {
  color: red;
  font-weight: bold;
}

.ModalTricky-Computer-Password .Success {
  color: green;
  font-weight: bold;
}

.ModalTricky-Computer-Email p {
  margin: 20px !important;
  font-weight: 500 !important;
  font-size: 1.4vw;
  color: #23345a !important;
}

.ModalTricky-Computer-Email .Button-Email {
  display: flex;
  justify-content: center;
}

.ModalTricky-Computer-Email .ValidButton,
.ModalTricky-Computer-Email .CancelButton {
  /* margin: 20px; */
  z-index: 1;
  cursor: pointer;
}

.ModalTricky-Computer-Email input {
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #000;
  font-size: 1.4vw;
  font-weight: 500;
}

.word1 {
  width: 3vw;
  color: red !important;
}
.word1-good {
  width: 3vw;
  color: green !important;
}

.word2 {
  width: 7.8vw;
  color: red !important;
}
.word2-good {
  width: 7.8vw;
  color: green !important;
}

.word3 {
  width: 5.6vw;
  color: red !important;
}
.word3-good {
  width: 5.6vw;
  color: green !important;
}

.word4 {
  width: 10vw;
  color: red !important;
}
.word4-good {
  width: 10vw;
  color: green !important;
}

.word1:focus,
.word2:focus,
.word3:focus,
.word4:focus {
  outline: none;
}

.PowerChord {
  width: 100%;
  position: relative;
}

.textPowerChord {
  position: absolute;
  color: #fff;
  top: 5%;
  font-size: 2rem;
}

.btnPowerChord {
  font-size: 1.5rem;
  position: absolute;
  top: 30%;
  left: 50%;
}

.Img-vision {
  width: 60%;
  align-self: center;
}
/* BOARD */

.ModalTricky-Board-Container {
  background-image: url(/static/media/Tableau_background_etire_t0nqm9.ca698722.svg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Board-Melanie img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 18%;
  left: 34.5%;
  cursor: pointer;
}

.Board-Chloe img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 27%;
  left: 61%;
  cursor: pointer;
}

.Board-Marion img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 55%;
  left: 41%;
  cursor: pointer;
}

.Board-Pierre img {
  position: absolute;
  z-index: 2;
  height: 21%;
  top: 69.5%;
  left: 64.5%;
  cursor: pointer;
}

/*----TELEPHONE----*/

.ModalTricky-Phone-Number form {
  position: relative;
}

.ModalTricky-Phone-Number img {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  width: 54vw;
}

.phone-number-input {
  position: absolute;
  top: 8.5%;
  left: 31.5%;
  transform: skew(-18deg, -2deg);
  background-color: transparent;
  font-size: 2.2vw;
  width: 23%;
  height: 10%;
  border-style: none;
}

.phone-number-input:focus {
  outline: none;
}

.container_pad {
  display: inline-flex;
  flex-wrap: wrap;
  width: 15vw;
  position: absolute;
  top: 29%;
  left: 27%;
  transform: skew(-6deg);
  white-space: nowrap;
}

.phone_button {
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  background-color: black;
  border-radius: 8px;
  display: inline-block;
  margin: 3px;
}

.inner-phone-button {
  text-align: center;
  width: 4.5vw;
  height: 2.2vw;
  color: black;
  text-decoration: none;
  display: inline-block;
  background-color: white;
  border-radius: inherit;
  border: 2px solid black;
  padding: 0 5px;
  text-transform: uppercase;
  transform: translate3d(-5px, -4px, 0);
  transition: transform 80ms ease;
  transition-delay: 50ms;
}

.inner-phone-button:active {
  transform: translate3d(0, 0, 0);
  transition: transform 80ms ease;
}

.inner-phone-button:focus {
  outline: none;
}

.fa-backspace {
  color: black;
}

/*------------------*/

#PostitMelanie {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 22%;
  left: 69%;
}

#PostitChloe {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 27%;
  left: 76.3%;
}

#PostitMarion {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 37%;
  left: 70.5%;
}

#PostitPierre {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 44.3%;
  left: 77%;
}

.Board-Buttons {
  display: flex;
}

/* ANIMATIONS */

.ItemToShow {
  height: 60%;
  width: 100%;
}

.ItemToShow img {
  -webkit-animation: showitem 0.5s linear;
          animation: showitem 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  height: 100%;
}

.zoomleft {
  -webkit-animation: zoomleft 0.5s linear;
          animation: zoomleft 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 5;
  position: absolute;
  display: block;
}

.zoomleft img {
  height: 100%;
}

@-webkit-keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes zoomleft {
  from {
    opacity: 1;
    top: 61%;
    right: 41%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 40%;
    right: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}

@keyframes zoomleft {
  from {
    opacity: 1;
    top: 61%;
    right: 41%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 40%;
    right: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}

/* ICONS SVG */

.next-ingame {
  -webkit-filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
          filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 20px;
}

.check-ingame {
  -webkit-filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
          filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 30px !important;
}

@-webkit-keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {
  .ModalTricky-Emotion-Card-Text {
    transform: translateY(33%);
  }
}

@media screen and (max-width: 1410px) {
  .ModalTricky-Emotion-Card h5 {
    font-size: 1rem;
  }
  .ModalTricky-Emotion-Card p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1250px) {
  .ModalTricky-Emotion-Card p {
    font-size: 0.8rem;
  }
}

/* MODALEMOTIONCARD */

.ModalEmotionCards {
  height: 100%;
}
.ModalEmotionCard_final {
  height: 100%;
  width: 100%;
}

.ModalEmotionCard_final .ModalEmotionCard_cards {
  margin-top: 5%;
  height: 90%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.ModalEmotionCard_notfinal {
  height: 100%;
  width: 100%;
}

.ModalEmotionCard_cards {
  height: 80%;
  margin-top: 20%;
}

.ModalEmotionCard_card_notfinal {
  height: 90%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  flex: auto;
  transition: transform 0.5s;
}

.ModalEmotionCard_card_notfinal:hover {
  transform: scale(1.1);
}

.ModalEmotionCard_card_back_no-description {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.TrickyHead img {
  height: 45%;
  top: 25%;
}

.TrickyHeadPrimary img {
  height: 35%;
  width: 100%;
  top: 30%;
}

.ModalEmotionCard_card_final {
  height: 130px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  flex: auto;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 25px;
}

.ModalEmotionCard_card_title_final {
  text-transform: uppercase;
  font-weight: bold;
}

.ModalEmotionCard_card_title_notfinal {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  font-size: 0.8vw !important;
  width: 55%;
  display: flex;
  display: -webkit-flex;
  color: #fff;
  font-weight: 700 !important;
}

.ModalEmotionCard_card_description {
  font-size: 0.65vw !important;
  width: 55%;
  text-align: left !important;
  margin-top: 0px;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  font-weight: 500 !important;
}

.ModalEmotionCard_card_face_1,
.ModalEmotionCard_card_face_26,
.ModalEmotionCard_card_face_33,
.ModalEmotionCard_card_face_35,
.ModalEmotionCard_card_face_29,
.ModalEmotionCard_card_face_49,
.ModalEmotionCard_card_face_53,
.ModalEmotionCard_card_face_59,
.ModalEmotionCard_card_face_62 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}
.ModalEmotionCard_card_face_2,
.ModalEmotionCard_card_face_28,
.ModalEmotionCard_card_face_40,
.ModalEmotionCard_card_face_38,
.ModalEmotionCard_card_face_32,
.ModalEmotionCard_card_face_52,
.ModalEmotionCard_card_face_56,
.ModalEmotionCard_card_face_60,
.ModalEmotionCard_card_face_64 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}
.ModalEmotionCard_card_face_23,
.ModalEmotionCard_card_face_27,
.ModalEmotionCard_card_face_34,
.ModalEmotionCard_card_face_36,
.ModalEmotionCard_card_face_30,
.ModalEmotionCard_card_face_50,
.ModalEmotionCard_card_face_54,
.ModalEmotionCard_card_face_57,
.ModalEmotionCard_card_face_63 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.ModalEmotionCard_card_face_24,
.ModalEmotionCard_card_face_44,
.ModalEmotionCard_card_face_41,
.ModalEmotionCard_card_face_37,
.ModalEmotionCard_card_face_31,
.ModalEmotionCard_card_face_51,
.ModalEmotionCard_card_face_55,
.ModalEmotionCard_card_face_58,
.ModalEmotionCard_card_face_61 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
}

.ModalEmotionCard_card_face_25,
.ModalEmotionCard_card_face_45,
.ModalEmotionCard_card_face_46,
.ModalEmotionCard_card_face_47,
.ModalEmotionCard_card_face_48 {
  background-image: url(/static/media/emotion_cards_primary.a84c658c.svg);
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  background-position: center;
}

.ModalEmotionCard_card_face_25 .ModalEmotionCard_card_description,
.ModalEmotionCard_card_face_45 .ModalEmotionCard_card_description {
  color: #ffffff !important;
}

.ModalEmotionCard_cards_primary {
  height: 40%;
}
.ModalEmotionCard_cards_secondary {
  display: flex;
  display: -webkit-flex;
  height: 50%;
}

.ModalEmotionCard_card_back_not_found {
  background-image: url(/static/media/emotion_cards_primary.a84c658c.svg);
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  background-position: center;
  height: 100%;
}
.ModalEmotionCard_card_back_1,
.ModalEmotionCard_card_back_2,
.ModalEmotionCard_card_back_3,
.ModalEmotionCard_card_back_4 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
}

/* MODALFOUNDCARD */

#Layer_1 {
  height: 50%;
  margin: auto;
}

.ModalPrimaryCard-Sedondary {
  position: relative;
}

.cardSideBack {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: 80%;
  position: absolute;
  top: 10%;
  left: 32.8%;
  width: 34.2%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  -webkit-animation: flipBack 2s ease-out forwards;
          animation: flipBack 2s ease-out forwards;
  background-image: linear-gradient(to right bottom, #2a3585, #9b3b83);
}

.cardSideBackPrimary {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: 80%;
  top: 10%;
  left: 32.8%;
  width: 34.2%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  /* animation: flipBack 2s ease-out forwards; */
  background-image: linear-gradient(to right bottom, #2a3585, #9b3b83);
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.cardSideFront {
  perspective-origin: 50% 30%;
  height: 80%;
  width: 35%;
  transform: rotateY(-180deg);
  -webkit-animation: flipFront 2s ease-out forwards;
          animation: flipFront 2s ease-out forwards;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cardFoundTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5vw;
  width: 75%;
  color: #fff;
  font-weight: 700 !important;
}

.cardFoundTitleSmall {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  font-size: 2vw;
  width: 75%;
  color: #fff;
  font-weight: 700 !important;
}

.cardFoundText {
  width: 75%;
  margin-top: 0px;
  font-size: 1.3vw;
  color: #fff;
  font-weight: 500 !important;
}

.cardAway {
  -webkit-animation: getAway 1s ease forwards;
          animation: getAway 1s ease forwards;
  perspective-origin: 50% 30%;
  height: 80%;
  width: 35%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  position: absolute;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@-webkit-keyframes getAway {
  from {
    opacity: 1;
    right: 32.5%;
    top: 10%;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.13);
    right: 0%;
    top: -35%;
  }
}

@keyframes getAway {
  from {
    opacity: 1;
    right: 32.5%;
    top: 10%;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.13);
    right: 0%;
    top: -35%;
  }
}

@-webkit-keyframes flipFront {
  from {
    transform: rotateY(-180deg) scale(0.1);
  }
  50% {
    transform: rotateY(-180deg) scale(1);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes flipFront {
  from {
    transform: rotateY(-180deg) scale(0.1);
  }
  50% {
    transform: rotateY(-180deg) scale(1);
  }
  to {
    transform: rotateY(0);
  }
}

@-webkit-keyframes flipBack {
  from {
    transform: rotateY(0) scale(0.1);
  }
  50% {
    transform: rotateY(0) scale(1);
  }
  to {
    transform: rotateY(180deg);
  }
}

@keyframes flipBack {
  from {
    transform: rotateY(0) scale(0.1);
  }
  50% {
    transform: rotateY(0) scale(1);
  }
  to {
    transform: rotateY(180deg);
  }
}

/* MODALPRIMARYCARD */

.ModalPrimaryCard {
  height: 90%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  flex: auto;
  width: 100%;
  padding: 5px 10px;
}

.ModalPrimaryCardTitle {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4%;
  color: #fff;
  font-size: 1vw !important;
  width: 60%;
  margin-bottom: 10px;
  display: flex;
  display: -webkit-flex;
  font-weight: 700 !important;
}

.ModalPrimaryCardTitle2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.8vw !important;
  font-weight: 700 !important;
}

.ModalPrimaryCardMainEmotion {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5%;
  font-size: 1vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}
.ModalPrimaryCardSecondaryEmotion {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}
.ModalPrimaryCardEmotion {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 10%;
  font-size: 0.8vw !important;
  width: 65%;
  color: #fff;
  text-align: center;
  font-weight: 700 !important;
}

.ModalPrimaryCardDescription {
  width: 55% !important;
  font-size: 0.7vw !important;
  width: 60% !important;
  text-align: left !important;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  margin-top: 0px;
  font-weight: 500 !important;
}

.ModalEmotionCardPrimary-Switch {
  -webkit-animation: flipFrontPrimary 1s ease-out forwards;
          animation: flipFrontPrimary 1s ease-out forwards;
  perspective-origin: 50% 30%;
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  background-position: center;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimary_face_1,
.ModalEmotionCardPrimary_face_26,
.ModalEmotionCardPrimary_face_33,
.ModalEmotionCardPrimary_face_35,
.ModalEmotionCardPrimary_face_29,
.ModalEmotionCardPrimary_face_49,
.ModalEmotionCardPrimary_face_53,
.ModalEmotionCardPrimary_face_59,
.ModalEmotionCardPrimary_face_62 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 3%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-animation: bounceCard 2s ease infinite;
          animation: bounceCard 2s ease infinite;
}
.ModalEmotionCardPrimary_face_2,
.ModalEmotionCardPrimary_face_28,
.ModalEmotionCardPrimary_face_40,
.ModalEmotionCardPrimary_face_38,
.ModalEmotionCardPrimary_face_32,
.ModalEmotionCardPrimary_face_52,
.ModalEmotionCardPrimary_face_56,
.ModalEmotionCardPrimary_face_60,
.ModalEmotionCardPrimary_face_64 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 26%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-animation: bounceCard 2s ease infinite;
          animation: bounceCard 2s ease infinite;
}
.ModalEmotionCardPrimary_face_23,
.ModalEmotionCardPrimary_face_27,
.ModalEmotionCardPrimary_face_34,
.ModalEmotionCardPrimary_face_36,
.ModalEmotionCardPrimary_face_30,
.ModalEmotionCardPrimary_face_50,
.ModalEmotionCardPrimary_face_54,
.ModalEmotionCardPrimary_face_57,
.ModalEmotionCardPrimary_face_63 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 26%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-animation: bounceCard 2s ease infinite;
          animation: bounceCard 2s ease infinite;
}

.ModalEmotionCardPrimary_face_24,
.ModalEmotionCardPrimary_face_44,
.ModalEmotionCardPrimary_face_41,
.ModalEmotionCardPrimary_face_37,
.ModalEmotionCardPrimary_face_31,
.ModalEmotionCardPrimary_face_51,
.ModalEmotionCardPrimary_face_55,
.ModalEmotionCardPrimary_face_58,
.ModalEmotionCardPrimary_face_61 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 3%;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-animation: bounceCard 2s ease infinite;
          animation: bounceCard 2s ease infinite;
}

@-webkit-keyframes bounceCard {
  from {
    transform: rotateZ(0deg);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  45% {
    transform: rotateZ(1deg);
  }
  55% {
    transform: rotateZ(-1deg);
  }
  70% {
    transform: rotateZ(1deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}

@keyframes bounceCard {
  from {
    transform: rotateZ(0deg);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  45% {
    transform: rotateZ(1deg);
  }
  55% {
    transform: rotateZ(-1deg);
  }
  70% {
    transform: rotateZ(1deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}

.ModalEmotionCardPrimary_face_25,
.ModalEmotionCardPrimary_face_45,
.ModalEmotionCardPrimary_face_46,
.ModalEmotionCardPrimary_face_47,
.ModalEmotionCardPrimary_face_48 {
  background-image: url(/static/media/emotion_cards_primary.a84c658c.svg);
  background-repeat: no-repeat;
  align-items: flex-start;
  display: flex;
  display: -webkit-flex;
  height: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
  background-size: contain;
}

.ModalEmotionCardPrimaryReversing_face_1,
.ModalEmotionCardPrimaryReversing_face_26,
.ModalEmotionCardPrimaryReversing_face_33,
.ModalEmotionCardPrimaryReversing_face_35,
.ModalEmotionCardPrimaryReversing_face_29,
.ModalEmotionCardPrimaryReversing_face_49,
.ModalEmotionCardPrimaryReversing_face_53,
.ModalEmotionCardPrimaryReversing_face_59,
.ModalEmotionCardPrimaryReversing_face_62 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  -webkit-animation: flipFrontPrimary 1s ease-out forwards;
          animation: flipFrontPrimary 1s ease-out forwards;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 3%;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}
.ModalEmotionCardPrimaryReversing_face_2,
.ModalEmotionCardPrimaryReversing_face_28,
.ModalEmotionCardPrimaryReversing_face_40,
.ModalEmotionCardPrimaryReversing_face_38,
.ModalEmotionCardPrimaryReversing_face_32,
.ModalEmotionCardPrimaryReversing_face_52,
.ModalEmotionCardPrimaryReversing_face_56,
.ModalEmotionCardPrimaryReversing_face_60,
.ModalEmotionCardPrimaryReversing_face_64 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  left: 26%;
  z-index: 2;
  background-position: center;
  -webkit-animation: flipFrontPrimary 1s ease-out forwards;
          animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversing_face_23,
.ModalEmotionCardPrimaryReversing_face_27,
.ModalEmotionCardPrimaryReversing_face_34,
.ModalEmotionCardPrimaryReversing_face_36,
.ModalEmotionCardPrimaryReversing_face_30,
.ModalEmotionCardPrimaryReversing_face_50,
.ModalEmotionCardPrimaryReversing_face_54,
.ModalEmotionCardPrimaryReversing_face_57,
.ModalEmotionCardPrimaryReversing_face_63 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 26%;
  z-index: 2;
  background-position: center;
  -webkit-animation: flipFrontPrimary 1s ease-out forwards;
          animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}
.ModalEmotionCardPrimaryReversing_face_24,
.ModalEmotionCardPrimaryReversing_face_44,
.ModalEmotionCardPrimaryReversing_face_41,
.ModalEmotionCardPrimaryReversing_face_37,
.ModalEmotionCardPrimaryReversing_face_31,
.ModalEmotionCardPrimaryReversing_face_51,
.ModalEmotionCardPrimaryReversing_face_55,
.ModalEmotionCardPrimaryReversing_face_58,
.ModalEmotionCardPrimaryReversing_face_61 {
  background-image: url(/static/media/emotion_cards_secondary_front.9002291d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  height: 45%;
  right: 3%;
  z-index: 2;
  background-position: center;
  -webkit-animation: flipFrontPrimary 1s ease-out forwards;
          animation: flipFrontPrimary 1s ease-out forwards;
  perspective: 50rem;
  transform: rotateY(-180deg);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_1,
.ModalEmotionCardPrimaryReversed_face_26,
.ModalEmotionCardPrimaryReversed_face_33,
.ModalEmotionCardPrimaryReversed_face_35,
.ModalEmotionCardPrimaryReversed_face_29,
.ModalEmotionCardPrimaryReversed_face_49,
.ModalEmotionCardPrimaryReversed_face_53,
.ModalEmotionCardPrimaryReversed_face_59,
.ModalEmotionCardPrimaryReversed_face_62 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  left: 3%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_2,
.ModalEmotionCardPrimaryReversed_face_28,
.ModalEmotionCardPrimaryReversed_face_40,
.ModalEmotionCardPrimaryReversed_face_38,
.ModalEmotionCardPrimaryReversed_face_32,
.ModalEmotionCardPrimaryReversed_face_52,
.ModalEmotionCardPrimaryReversed_face_56,
.ModalEmotionCardPrimaryReversed_face_60,
.ModalEmotionCardPrimaryReversed_face_64 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  left: 26%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversed_face_23,
.ModalEmotionCardPrimaryReversed_face_27,
.ModalEmotionCardPrimaryReversed_face_34,
.ModalEmotionCardPrimaryReversed_face_36,
.ModalEmotionCardPrimaryReversed_face_30,
.ModalEmotionCardPrimaryReversed_face_50,
.ModalEmotionCardPrimaryReversed_face_54,
.ModalEmotionCardPrimaryReversed_face_57,
.ModalEmotionCardPrimaryReversed_face_63 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  right: 26%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}
.ModalEmotionCardPrimaryReversed_face_24,
.ModalEmotionCardPrimaryReversed_face_44,
.ModalEmotionCardPrimaryReversed_face_41,
.ModalEmotionCardPrimaryReversed_face_37,
.ModalEmotionCardPrimaryReversed_face_31,
.ModalEmotionCardPrimaryReversed_face_51,
.ModalEmotionCardPrimaryReversed_face_55,
.ModalEmotionCardPrimaryReversed_face_58,
.ModalEmotionCardPrimaryReversed_face_61 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 22%;
  height: 45%;
  right: 3%;
  visibility: hidden;
  background-position: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_1,
.ModalEmotionCardPrimaryReversedOn_face_26,
.ModalEmotionCardPrimaryReversedOn_face_33,
.ModalEmotionCardPrimaryReversedOn_face_35,
.ModalEmotionCardPrimaryReversedOn_face_29,
.ModalEmotionCardPrimaryReversedOn_face_49,
.ModalEmotionCardPrimaryReversedOn_face_53,
.ModalEmotionCardPrimaryReversedOn_face_59,
.ModalEmotionCardPrimaryReversedOn_face_62 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  -webkit-animation: flipBackPrimary 1s ease-out forwards;
          animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  left: 3%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_2,
.ModalEmotionCardPrimaryReversedOn_face_28,
.ModalEmotionCardPrimaryReversedOn_face_40,
.ModalEmotionCardPrimaryReversedOn_face_38,
.ModalEmotionCardPrimaryReversedOn_face_32,
.ModalEmotionCardPrimaryReversedOn_face_52,
.ModalEmotionCardPrimaryReversedOn_face_56,
.ModalEmotionCardPrimaryReversedOn_face_60,
.ModalEmotionCardPrimaryReversedOn_face_64 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  -webkit-animation: flipBackPrimary 1s ease-out forwards;
          animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  left: 26%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_23,
.ModalEmotionCardPrimaryReversedOn_face_27,
.ModalEmotionCardPrimaryReversedOn_face_34,
.ModalEmotionCardPrimaryReversedOn_face_36,
.ModalEmotionCardPrimaryReversedOn_face_30,
.ModalEmotionCardPrimaryReversedOn_face_50,
.ModalEmotionCardPrimaryReversedOn_face_54,
.ModalEmotionCardPrimaryReversedOn_face_57,
.ModalEmotionCardPrimaryReversedOn_face_63 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  -webkit-animation: flipBackPrimary 1s ease-out forwards;
          animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  right: 26%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.ModalEmotionCardPrimaryReversedOn_face_24,
.ModalEmotionCardPrimaryReversedOn_face_44,
.ModalEmotionCardPrimaryReversedOn_face_41,
.ModalEmotionCardPrimaryReversedOn_face_37,
.ModalEmotionCardPrimaryReversedOn_face_31,
.ModalEmotionCardPrimaryReversedOn_face_51,
.ModalEmotionCardPrimaryReversedOn_face_55,
.ModalEmotionCardPrimaryReversedOn_face_58,
.ModalEmotionCardPrimaryReversedOn_face_61 {
  background-image: url(/static/media/emotion_cards_secondary_back.e24f73e8.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25%;
  position: absolute;
  visibility: hidden;
  background-position: center;
  -webkit-animation: flipBackPrimary 1s ease-out forwards;
          animation: flipBackPrimary 1s ease-out forwards;
  perspective: 50rem;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  height: 45%;
  right: 3%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
}

.path_container {
  position: absolute;
  width: 70%;
  left: 15%;
}

.path {
  stroke-linecap: round;
  fill: transparent;
  stroke: salmon;
  stroke-width: 6;
  stroke-linecap: round;
}

.path2 {
  opacity: 0.2;
}

.line1opacity {
  stroke-dasharray: 346;
  stroke-dashoffset: 346;
  stroke-dashoffset: 0;
}
.line2opacity {
  stroke-dasharray: 148;
  stroke-dashoffset: 148;
  stroke-dashoffset: 0;
}
.line3opacity {
  stroke-dasharray: 148;
  stroke-dashoffset: 148;
  stroke-dashoffset: 0;
}
.line4opacity {
  stroke-dasharray: 346;
  stroke-dashoffset: 346;
  stroke-dashoffset: 0;
}

.line1 {
  stroke-dasharray: 422;
  stroke-dashoffset: 422;
  -webkit-animation: dash 1s ease-in-out forwards;
          animation: dash 1s ease-in-out forwards;
}

.line2 {
  stroke-dasharray: 244;
  stroke-dashoffset: 244;
  -webkit-animation: dash 1s ease-in-out forwards;
          animation: dash 1s ease-in-out forwards;
}

.line3 {
  stroke-dasharray: 244;
  stroke-dashoffset: 244;
  -webkit-animation: dash 1s ease-in-out forwards;
          animation: dash 1s ease-in-out forwards;
}

.line4 {
  stroke-dasharray: 422;
  stroke-dashoffset: 422;
  -webkit-animation: dash 1s ease-in-out forwards;
          animation: dash 1s ease-in-out forwards;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes flipFrontPrimary {
  from {
    transform: rotateY(0deg);
  }
  50% {
    z-index: 2;
  }
  to {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
}

@keyframes flipFrontPrimary {
  from {
    transform: rotateY(0deg);
  }
  50% {
    z-index: 2;
  }
  to {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
}

@-webkit-keyframes flipBackPrimary {
  from {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
  80% {
    z-index: 3;
  }
  to {
    transform: rotateY(0);
    visibility: visible;
    z-index: 3;
  }
}

@keyframes flipBackPrimary {
  from {
    transform: rotateY(-180deg);
    visibility: hidden;
  }
  80% {
    z-index: 3;
  }
  to {
    transform: rotateY(0);
    visibility: visible;
    z-index: 3;
  }
}

.ZoomDrawer {
  position: relative;
  width: 100%;
  height: 100%;
}

.ZoomDrawerKnife {
  position: absolute;
  background: url(/static/media/BUREAU-4_tirroir-1_CROPPED-01.4d9331a6.svg)
    no-repeat;
  background-position: center;
  width: 100%;
  height: 70%;
}

.ZoomDrawerKnife img {
  top: 8%;
  left: 38%;
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}

.drawer img {
  position: absolute;
  z-index: 3;
  height: 11.5%;
  top: 80.1%;
  left: 54.2%;
}

.Drawer-Open {
  height: 150px;
  position: relative;
  opacity: 60%;
  margin-top: 60px;
}

.Drawer-Knife img {
  width: 100%;
  z-index: 1;
}

#button1-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
}

#button1-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button1-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button1-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

#button2-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
}

#button2-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button2-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button2-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

#button3-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
}

#button3-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button3-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button3-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

.zoom-drawer {
  position: relative;
  background: url(/static/media/BUREAU-4_tirroir-1_CROPPED-01.4d9331a6.svg)
    no-repeat;
  background-position: center;
  width: 350px;
  height: 150px;
  margin-bottom: 15px;
}

#zoom-button1-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button1-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button1-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button1-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button2-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button2-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button2-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button2-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button3-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button3-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button3-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button3-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#countdown {
  position: relative;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
  transform: scale(2);
}

#countdownTitle {
  display: flex;
  position: absolute;
  top: 70%;
  -webkit-animation: bounceTitle 1s ease infinite;
          animation: bounceTitle 1s ease infinite;
}

#countdownTitle h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle3 {
  display: flex;
  position: absolute;
  top: 70%;
}

#countdownTitle3 h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle2 h4 {
  color: #fff;
  font-size: 4vw;
}

#countdown-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

#svg,
#svg2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  -webkit-animation: countdownArrival 0.5s linear forwards;
          animation: countdownArrival 0.5s linear forwards;
}

#svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  -webkit-animation: countdown 4.5s linear forwards;
          animation: countdown 4.5s linear forwards;
}

#svg2 circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  stroke-dashoffset: 0px;
}

@-webkit-keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@-webkit-keyframes countdownArrival {
  from {
    transform: scale(7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes countdownArrival {
  from {
    transform: scale(7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes bounceTitle {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes bounceTitle {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

/* OFFICE */

.clock-office img {
  position: absolute;
  z-index: 2;
  height: 8%;
  top: 15.5%;
  left: 43%;
}

.hat-office img {
  position: absolute;
  z-index: 2;
  height: 15%;
  top: 27%;
  left: 25%;
}

.trash-office img {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 80.5%;
  left: 80%;
}

.desktoplamp-office img {
  position: absolute;
  z-index: 3;
  height: 21.5%;
  top: 50.5%;
  left: 33.3%;
}

.desktoppapers-office img {
  position: absolute;
  z-index: 3;
  width: 17%;
  top: 70.2%;
  left: 33.3%;
}

.desktoptoplamp-office img {
  position: absolute;
  z-index: 2;
  width: 31%;
  top: 3.2%;
  left: 32.7%;
}

.desktopdoor-office img {
  position: absolute;
  z-index: 2;
  height: 66%;
  top: 13.2%;
  right: 3.5%;
}

.desktopcupboard-office img {
  position: absolute;
  z-index: 2;
  height: 70%;
  top: 23.2%;
  left: -0.5%;
}

.desktopchair-office img {
  position: absolute;
  z-index: 1;
  height: 44%;
  top: 52.2%;
  left: 43%;
}

.desktoplockers-office img {
  position: absolute;
  z-index: 0;
  width: 32%;
  top: 23.6%;
  left: 27.2%;
}

.desktopdesktop-office img {
  position: absolute;
  z-index: 2;
  width: 50.5%;
  bottom: 0.6%;
  left: 23.5%;
}

.Notifications-Container {
  /* border: 2px solid white; */
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0%;
  z-index: 5;
}

.Notifications {
  /* padding: 10px; */
  padding-top: 1%;
  background-color: rgba(0, 0, 0, 12);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-family: Helvetica, Arial, sans-serif;
  transition: transform 0.5s;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  -webkit-animation: slide 5s ease;
          animation: slide 5s ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
}

.Notifications p {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.2rem;
  padding: 5px 0px 10px 0px;
}

@-webkit-keyframes slide {
  from {
    top: 0%;
    display: block;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    top: 0%;
    z-index: 0;
    opacity: 0;
  }
}

@keyframes slide {
  from {
    top: 0%;
    display: block;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    top: 0%;
    z-index: 0;
    opacity: 0;
  }
}

.Gameboard-container {
  border-radius: 5px;
  width: 60%;
  background: #66566d;
  margin: 0px 5px;
  cursor: url(/static/media/pink.a307ee05.cur), default;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Gameboard-container div,
.Gameboard-container img {
  cursor: url(/static/media/pink.a307ee05.cur), default;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Gameboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gameboard-container h1,
.Gameboard-container h2,
.Gameboard-container h3 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 1.5rem !important;
  margin: 15px;
}

.Gameboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Gameinfo {
  margin: 10px 30px;
  padding: 10px;
  background: #2d1837;
}

.Gameinfo p {
  margin: 15px;
}

.Timer h1 {
  font-family: 'Roboto';
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;
}

/* GAMEBOARD HEADER */

.Gameboard-Header {
  position: absolute;
  width: 23%;
  height: 11%;
  z-index: 4;
  border-bottom-left-radius: 5% 15%;
  background: linear-gradient(90deg, #d4507a, #dc4b50);
  right: 0;
}

.GameBoardHeader_cards {
  display: flex;
  justify-content: center;
  height: 100%;
}

.GameBoardHeader_card {
  background-repeat: no-repeat;
}

.GameBoardHeader_card p {
  opacity: 0;
}

.card_primary {
  background-image: url(/static/media/mini_primary_emotion_card.b978f7d1.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.card_secondary_1 {
  background-image: url(/static/media/mini_emotion_card_1.45b7ecad.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}
.card_secondary_2 {
  background-image: url(/static/media/mini_emotion_card_2.9a2e3ba0.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}
.card_secondary_3 {
  background-image: url(/static/media/mini_emotion_card_3.d5d209fc.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}
.card_secondary_4 {
  background-image: url(/static/media/mini_emotion_card_4.67357dec.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}
.GameBoardHeader_card_found {
  background-color: #2d1937;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  color: white;
  margin-top: 60%;
  font-size: smaller;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.card_disabled {
  opacity: 0.3;
}

.ModalTricky-Congrat-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  background: #fff;
  border-radius: 5px;
}

.ModalTricky-Bravo {
  width: 60%;
  margin: -5px auto;
  text-align: center;
  position: relative;
  padding: 10px 0px;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  box-shadow: 0px 0px 5px #454545;
  border-radius: 10px/50%;
}

.ModalTricky-Bravo h2 {
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
}

.ModalTricky-congrat-p {
  text-align: center;
  color: #000;
  width: 75%;
  margin: 0px auto;
}

.ModalTricky-Form {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.Congrat-Bottom {
  display: flex;
  width: 100%;
}

.ModalTricky-Form {
  width: 50%;
}

.ModalTricky-Fox {
  width: 50%;
  position: relative;
}

.Congrat-Input-Large {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Medium {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Large input,
.Congrat-Input-Medium input {
  margin: 5px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.Congrat-Input-Large input {
  width: 100%;
}

::-webkit-input-placeholder {
  color: #d9d9d9;
}

:-ms-input-placeholder {
  color: #d9d9d9;
}

::-ms-input-placeholder {
  color: #d9d9d9;
}

::placeholder {
  color: #d9d9d9;
}

/* FOX */

.FoxBody-Congrat img {
  z-index: 1;
  bottom: 4%;
  right: 22%;
  width: 50%;
  position: absolute;
}

.FoxBody-Congrat .FoxBody-Congrat {
  width: 350px;
  height: 20%;
  position: relative;
  bottom: 0;
}

.FoxFur-Congrat img {
  position: absolute;
  z-index: 1;
  bottom: 32%;
  right: 40%;
  -webkit-animation: breathing 6s ease infinite;
          animation: breathing 6s ease infinite;
}

.FoxHead-Congrat img {
  position: absolute;
  z-index: 2;
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movinghead 10s ease infinite;
          animation: movinghead 10s ease infinite;
}

.FoxHead-Congrat img:hover {
  position: absolute;
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movingheadfast 0.5s ease;
          animation: movingheadfast 0.5s ease;
}

.FoxHead-Tutorial img {
  z-index: 2;
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movinghead 10s ease infinite;
          animation: movinghead 10s ease infinite;
}

.FoxHead-Tutorial img:hover {
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movingheadfast 0.5s ease;
          animation: movingheadfast 0.5s ease;
}

@-webkit-keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}

@keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}

.ModalTricky-Scoreboard-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
  border-radius: 5px;
  border: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  border-image-slice: 1;
}

.ModalTricky-Scoreboard-Scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
}

.ModalTricky-Scoreboard-info {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  color: #fff;
  text-align: center;
}

.ModalTricky-Scoreboard-players {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  background: #fff;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

.ModalTricky-Scoreboard-User {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  background-image: linear-gradient(
    90deg,
    #e76322,
    #d93a5b,
    #cf1c83,
    #792887,
    #552d88
  );
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

.ModalTricky-Scoreboard-players:hover {
  background: #e8e8e8;
  font-weight: bold;
}

.col-10 {
  width: 10%;
}

.col-20 {
  width: 20%;
  cursor: pointer;
}

.col-50 {
  width: 50%;
  text-align: left;
  padding-left: 15px;
}

.scoreboard-pseudo {
  font-weight: bold;
  color: #e3505d;
}

.scoreboard-rank {
  font-weight: bold;
  color: #4d3990;
}

.score-selected {
  border: 1px solid #fff;
  border-radius: 5px;
}

.scoreboard-user {
  font-weight: bold;
  color: #fff;
}

.Dashboard-container {
  border: 3px solid transparent;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 20%;
  position: relative;
}

.Dashboard-container h2 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
  font-size: 1.3rem;
}

.Dashboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Dashboard-p p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 15px auto !important;
  text-align: center;
  cursor: pointer;
}

.Dashboard-p,
.Dashboard-Preco {
  text-decoration: none;
}

/* SOUND GAME */

.Sound-Game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #2d1837;
  margin: 5px;
  /* border: 4px solid #45354c; */
  opacity: 0.5;
  position: relative;
  cursor: pointer;
}

.Sound-Game img {
  width: 20px;
  height: 20px;
}

/* TURN COUNTER */

.TurnCounter-container,
.Time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background: #2d1837;
  margin: 5px;
  position: relative;
}

.TurnCounter-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border: 4px solid red;
}

.TurnCounter-container2 h2 {
  background: none !important;
  margin-bottom: 5%;
}

.TurnCounter-container-time {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  border: 4px solid #45354c;
  position: relative;
  bottom: 0;
  left: 25%;
}

/* OBJECTS */

.Object-Item-Container {
  margin-bottom: 20px;
}

.Object-title {
  padding: 10px 0px 0px 10px;
  font-size: 1.5vw !important;
}

.ObjectList {
  display: flex;
  padding: 10px;
  position: relative;
  background: #2d1837;
  margin: 5px;
}

.Object-square {
  width: 100px;
  height: 100px;
  background: #635868;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Object-square img {
  width: 80px;
  height: 80px;
}

.Object-square .Object-Image {
  cursor: pointer;
}

.Object-name {
  padding: 10px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter .example-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave .example-leave-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.TurnCounter-container2 h2 {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 1rem !important;
  font-weight: 400 !important;
  opacity: 30%;
}

.timer .TimerText {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  opacity: 30%;
}

.timer .TimerText-time {
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10-time {
  text-transform: uppercase;
  text-align: center;
  color: #a30000;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10 {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  color: #a30000;
}

.NbClick2 {
  font-size: 1.7rem;
  font-weight: 700 !important;
}

.NbClick2--big {
  font-size: 1.7rem;
  font-weight: 700 !important;
  -webkit-animation: big2 1s ease;
          animation: big2 1s ease;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

@-webkit-keyframes big2 {
  from {
    font-size: 1.7rem;
    font-weight: 700 !important;
  }
  to {
    font-size: 2.4rem;
    font-weight: 1200 !important;
  }
}

@keyframes big2 {
  from {
    font-size: 1.7rem;
    font-weight: 700 !important;
  }
  to {
    font-size: 2.4rem;
    font-weight: 1200 !important;
  }
}

.NbClick {
  font-size: 1rem;
  font-weight: 500 !important;
}

.NbClick--big {
  font-size: 1rem;
  font-weight: 500 !important;
  -webkit-animation: big 1s ease;
          animation: big 1s ease;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

@-webkit-keyframes big {
  from {
    font-size: 1rem;
    font-weight: 500 !important;
  }
  to {
    font-size: 2rem;
    font-weight: 1000 !important;
  }
}

@keyframes big {
  from {
    font-size: 1rem;
    font-weight: 500 !important;
  }
  to {
    font-size: 2rem;
    font-weight: 1000 !important;
  }
}

.Bureau-img {
  position: relative;
  width: 80%;
  z-index: 0;
  overflow: hidden;
}

.Bureau-img-div:focus {
  outline: none;
}

.CloseWindow img {
  position: absolute;
  width: 5%;
  top: 5%;
  right: 5%;
}

.power-chord img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 21%;
  top: 72%;
  left: 13%;
}

.power-chord-plugged img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 27%;
  top: 64%;
  left: 13%;
}

.screen img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenPassword img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenEmail img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenSleep img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

/* FOLDER */

#letter {
  height: 80% !important;
}

.folder img {
  position: absolute;
  z-index: 1;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
  cursor: pointer;
}

.hide-folder img {
  position: absolute;
  z-index: 2;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
}

.letterroom img {
  position: absolute;
  -webkit-animation: zoomleftmulti 0.5s linear;
          animation: zoomleftmulti 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: block;
  z-index: 1;
}

.hide-folder {
  -webkit-animation: hidefolder 1s linear;
          animation: hidefolder 1s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes hidefolder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes hidefolder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

/* BOARD */

.board img {
  position: absolute;
  z-index: 0;
  height: 37.4%;
  top: 19.9%;
  left: 66.5%;
  cursor: pointer;
}

.modal-email {
  margin-top: 200px;
  background: #23345a;
  border: 10px solid #000000;
  border-radius: 20px;
  max-width: 1000px;
  min-height: 100px;
  padding: 25px;
  position: relative;
}

.email-container-image img {
  width: 90%;
}

#mug {
  height: 80% !important;
}

.mug img {
  position: absolute;
  z-index: 3;
  height: 8.5%;
  top: 61%;
  left: 41%;
  cursor: pointer;
}

.mug2 img {
  position: absolute;
  z-index: 3;
  height: 6.5%;
  top: 70%;
  left: 77%;
  cursor: pointer;
}

.phone img {
  position: absolute;
  z-index: 4;
  height: 13%;
  top: 64.4%;
  left: 25%;
  cursor: pointer;
}

.mug #smoke {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  -webkit-animation: smoke 2s infinite;
          animation: smoke 2s infinite;
  transform: rotate(18deg);
}
.mug #smoke2 {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke2:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  -webkit-animation: smoke2 5s infinite;
          animation: smoke2 5s infinite;
  transform: rotate(18deg);
}
@-webkit-keyframes smoke {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -20px -25px, -5px -25px, 0px -25px, 0px -25px;
    opacity: 0;
  }
}
@keyframes smoke {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -20px -25px, -5px -25px, 0px -25px, 0px -25px;
    opacity: 0;
  }
}
@-webkit-keyframes smoke2 {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -10px 15px, 5px -15px, 0px 15px, 0px 15px;
    opacity: 0;
  }
}
@keyframes smoke2 {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -10px 15px, 5px -15px, 0px 15px, 0px 15px;
    opacity: 0;
  }
}

/* MODALS */

.modal-body img {
  margin: 50px;
}

.hidden {
  display: none;
}

.Hide-Button {
  background-color: transparent;
  border: none;
  cursor: none;
}

@font-face {
  font-family: 'Urania';
  src: url(/static/media/Urania_Czech.e946d0fd.woff) format('woff');
}

@font-face {
  font-family: 'Karmatic Arcade';
  font-style: normal;
  font-weight: normal;
  src: local('Karmatic Arcade'), url(/static/media/ka1.385354ed.woff) format('woff');
}

/* MUG */

.Align-Center {
  align-self: center;
}

/* MODALS */

.ModalTricky-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition-duration: 0.5s;
}

.ModalTricky-Background h3 {
  color: #fff;
  margin-bottom: 0px;
}

.ModalTricky-Background-Time {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition-duration: 0.5s;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.ModalTricky-NoClick {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-NoClick2 {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 80%;
  height: 100%;
  z-index: 82;
  transition-duration: 0.5s;
}

.Prevention-Message {
  padding: 20px;
  color: #fff;
}

.ModalTricky-button {
  width: 100%;
}

.ModalTricky-button button {
  position: absolute;
  transform: translateX(-60%);
}

.ModalTrickyObject-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7%;
  left: 20%;
  width: 60%;
  height: 76.3%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Background-Position {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6.8%;
  left: 20.1%;
  width: 59.7%;
  height: 74%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Background2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  height: 70%;
  width: 70%;
}

.ModalTricky-TimeEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 70%;
}

.ModalTricky-Animation {
  width: 247.359px;
  height: 187.219px;
  margin-bottom: 70px;
}

.ModalTricky-Container-Powerchord {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
}

.ModalTricky-WrongObject {
  height: 100%;
  width: 100%;
}

.ModalTricky-WrongObject img {
  -webkit-animation: showwrongitem 0.5s ease;
          animation: showwrongitem 0.5s ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  height: 100%;
}

/* MODAL INTRO */

.ModalTricky-Intro {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-duration: 0.5s;
}

.ModalTricky-Intro-Container-DemoMulti {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}

.ModalTricky-Intro-Card-DemoMulti {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  padding: 0px 30px;
}

.ModalTricky-Intro-Text-DemoMulti {
  display: flex;
  flex-direction: column;
  line-height: 1.3rem;
}

.ModalTricky-Intro-Title-DemoMulti {
  font-family: 'Karmatic Arcade';
  font-size: 2rem;
  margin-bottom: 10px;
  color: #fff;
}

.ModalTricky-Intro-Title2 {
  font-family: 'Karmatic Arcade';
  font-size: 2rem;
  margin-bottom: 10px;
  color: #000;
}

.ModalTricky-Intro-TextGrey {
  color: #999999;
}

.fa-clock {
  color: #999999;
  font-size: 2rem;
}

.ModalTricky-Intro-Img img {
  object-fit: cover;
  height: 400px;
  width: 350px;
}

/* MODAL LETTER */

.opened-letter {
  height: 90% !important;
}

.Letter-Open {
  width: 250%;
  margin-bottom: 1px !important;
}

.Letter-Text {
  width: 165%;
  margin-bottom: 0 !important;
  position: relative;
}

.Letter-Text-Cont {
  position: absolute;
  top: 30%;
  width: 20%;
}

.Letter-Text-Cont p {
  color: #000000;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
  color: #fff;
}

.ModalTricky-p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.ModalObjectTricky-p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.ModalObjectTricky-p p {
  color: #fff;
}

.ModalObjectTricky-p img {
  width: 20%;
  margin-top: 30px;
  margin-bottom: 0;
}

.ModalObjectTricky-p .MugImg {
  width: 80%;
  margin-left: 70px;
}

.ModalObject-Button {
  display: flex;
}

.ModalTricky-Emotion-Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
}

.ModalTricky-Emotion-Card-Text {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(10%);
  width: 100%;
  text-align: center;
}

.ModalTricky-Emotion-Card-Text > p {
  color: #fff;
  width: 80%;
  margin: 2rem auto;
}

.ModalTricky-Emotion-Cards {
  display: flex;
  flex-direction: row;
}

.ModalTricky-Emotion-Cards-P {
  width: 20%;
  color: #000;
  background: #fff;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 5px hsl(0, 90%, 53%), 0 0 0 12px hsl(0, 0%, 100%);
}

.ModalTricky-Emotion-Card h5 {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h4 {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.ModalTricky-Emotion-Card h3 {
  color: #fff;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
}

.button-continue {
  position: absolute;
  bottom: 2%;
}

.Emotion-Card-Container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 5px;
  width: 90%;
  height: 90%;
}

.ModalTricky-Emotion-Card img {
  width: 95%;
  align-self: center;
  margin-bottom: 0px !important;
  position: relative;
}

.EmotionCont {
  display: flex;
}

.Emotion-Card {
  position: relative;
  width: 100%;
}

.Emotion-Card-Title-1 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-1 {
  position: absolute;
  top: 95px;
  left: 42px;
  width: 70%;
}

.Emotion-Card-Title-2 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-2 {
  position: absolute;
  top: 90px;
  left: 42px;
  width: 70%;
}

.ModalTricky-Emotion-Card-Text .fa-check-circle:before {
  margin: 5px 5px 0px 0px !important;
  color: #fff !important;
}

.Hide-Modal {
  display: none !important;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord img {
  width: 100%;
}

.ModalTricky-Computer {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 300px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Success {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid green;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Error {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid red;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Email {
  display: flex;
  padding: 0.5vw;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  background: #23345a;
  border: 0.5vw solid #000000;
  border-radius: 2vw;
}

.ModalTricky-Computer-Email-p {
  background: #fff;
  border-radius: 1vw;
}

.ModalTricky-Computer-Password label {
  color: #fff;
  margin-right: 10px;
}

.ModalTricky-Computer-Password .Error {
  color: red;
  font-weight: bold;
}

.ModalTricky-Computer-Password .Success {
  color: green;
  font-weight: bold;
}

.ModalTricky-Computer-Email p {
  margin: 20px !important;
  font-weight: 500 !important;
  font-size: 1.4vw;
  color: #23345a !important;
}

.ModalTricky-Computer-Email .Button-Email {
  display: flex;
  justify-content: center;
}

.ModalTricky-Computer-Email .ValidButton,
.ModalTricky-Computer-Email .CancelButton {
  /* margin: 20px; */
  z-index: 1;
  cursor: pointer;
}

.ModalTricky-Computer-Email input {
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #000;
  font-size: 1.4vw;
  font-weight: 500;
  color: #23345a;
}

.word1 {
  width: 3vw;
}

.word2 {
  width: 7.8vw;
}

.word3 {
  width: 5.6vw;
}

.word4 {
  width: 10vw;
}

.word1:focus,
.word2:focus,
.word3:focus,
.word4:focus {
  outline: none;
}

.PowerChord {
  width: 100%;
  position: relative;
}

.textPowerChord {
  position: absolute;
  color: #fff;
  top: 5%;
  font-size: 2rem;
}

.btnPowerChord {
  font-size: 1.5rem;
  position: absolute;
  top: 30%;
  left: 50%;
}

.Img-vision {
  width: 60%;
  align-self: center;
}
/* BOARD */

.ModalTricky-Board-Container {
  background-image: url(/static/media/Tableau_background_etire_t0nqm9.ca698722.svg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Board-Melanie img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 18%;
  left: 34.5%;
  cursor: pointer;
}

.Board-Chloe img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 27%;
  left: 61%;
  cursor: pointer;
}

.Board-Marion img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 55%;
  left: 41%;
  cursor: pointer;
}

.Board-Pierre img {
  position: absolute;
  z-index: 2;
  height: 21%;
  top: 69.5%;
  left: 64.5%;
  cursor: pointer;
}

/*----TELEPHONE----*/

.ModalTricky-Phone-Number form {
  position: relative;
}

.ModalTricky-Phone-Number img {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  width: 54vw;
}

.phone-number-input {
  position: absolute;
  top: 8.5%;
  left: 31.5%;
  transform: skew(-18deg, -2deg);
  background-color: transparent;
  font-size: 2.2vw;
  width: 23%;
  height: 10%;
  border-style: none;
}

.phone-number-input:focus {
  outline: none;
}

.container_pad {
  display: inline-flex;
  flex-wrap: wrap;
  width: 15vw;
  position: absolute;
  top: 29%;
  left: 27%;
  transform: skew(-6deg);
  white-space: nowrap;
}

.phone_button {
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  background-color: black;
  border-radius: 8px;
  display: inline-block;
  margin: 3px;
}

.inner-phone-button {
  text-align: center;
  width: 4.5vw;
  height: 2.2vw;
  font-size: 1rem;
  color: black;
  text-decoration: none;
  display: inline-block;
  background-color: white;
  border-radius: inherit;
  border: 2px solid black;
  padding: 0 5px;
  text-transform: uppercase;
  transform: translate3d(-5px, -4px, 0);
  transition: transform 80ms ease;
  transition-delay: 50ms;
}

.inner-phone-button:active {
  transform: translate3d(0, 0, 0);
  transition: transform 80ms ease;
}

.inner-phone-button:focus {
  outline: none;
}

.fa-backspace {
  color: black;
}

/*------------------*/

#PostitMelanie {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 22%;
  left: 69%;
}

#PostitChloe {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 27%;
  left: 76.3%;
}

#PostitMarion {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 37%;
  left: 70.5%;
}

#PostitPierre {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 44.3%;
  left: 77%;
}

.Board-Buttons {
  display: flex;
}

/* ANIMATIONS */

.ItemToShow {
  height: 60%;
  width: 100%;
}

.ItemToShow img {
  -webkit-animation: showitem 0.5s linear;
          animation: showitem 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  height: 100%;
}

.zoomleftmulti {
  -webkit-animation: zoomleftmulti 0.5s linear;
          animation: zoomleftmulti 0.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 5;
  position: absolute;
  display: block;
}

.zoomleftmulti img {
  height: 100%;
}

@-webkit-keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes zoomleftmulti {
  from {
    opacity: 1;
    top: 61%;
    left: 41%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 40%;
    left: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}

@keyframes zoomleftmulti {
  from {
    opacity: 1;
    top: 61%;
    left: 41%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 40%;
    left: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}

/* ICONS SVG */

.next-ingame {
  -webkit-filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
          filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 20px;
}

.check-ingame {
  -webkit-filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
          filter: invert(100%) sepia(3%) saturate(5644%) hue-rotate(229deg)
    brightness(120%) contrast(100%);
  width: 30px !important;
}

@-webkit-keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {
  .ModalTricky-Emotion-Card-Text {
    transform: translateY(33%);
  }
}

@media screen and (max-width: 1410px) {
  .ModalTricky-Emotion-Card h5 {
    font-size: 1rem;
  }
  .ModalTricky-Emotion-Card p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1250px) {
  .ModalTricky-Emotion-Card p {
    font-size: 0.8rem;
  }
}

.ZoomDrawer {
  position: relative;
  width: 100%;
  height: 100%;
}

.ZoomDrawerKnife {
  position: absolute;
  background: url(/static/media/BUREAU-4_tirroir-1_CROPPED-01.4d9331a6.svg)
    no-repeat;
  background-position: center;
  width: 100%;
  height: 70%;
}

.ZoomDrawerKnife img {
  top: 8%;
  left: 38%;
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}

.drawer img {
  position: absolute;
  z-index: 3;
  height: 11.5%;
  top: 79.4%;
  left: 54.2%;
}

.Drawer-Open {
  height: 150px;
  position: relative;
  opacity: 60%;
  margin-top: 60px;
}

.Drawer-Knife img {
  width: 100%;
  z-index: 1;
}

#button1-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
}

#button1-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button1-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button1-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 58.4%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

#button2-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
}

#button2-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button2-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button2-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 62.2%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

#button3-0 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
}

#button3-1 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(90deg);
}

#button3-2 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(180deg);
}

#button3-3 img {
  position: absolute;
  z-index: 4;
  height: 4.4%;
  top: 83.7%;
  left: 66%;
  transition-duration: 1s;
  transform: rotate(270deg);
}

.zoom-drawer {
  position: relative;
  background: url(/static/media/BUREAU-4_tirroir-1_CROPPED-01.4d9331a6.svg)
    no-repeat;
  background-position: center;
  width: 350px;
  height: 150px;
  margin-bottom: 15px;
}

#zoom-button1-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button1-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button1-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button1-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 22%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button2-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button2-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button2-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button2-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 42%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#zoom-button3-0 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  cursor: pointer;
}

#zoom-button3-1 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(90deg);
  cursor: pointer;
}

#zoom-button3-2 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(180deg);
  cursor: pointer;
}

#zoom-button3-3 img {
  position: absolute;
  z-index: 4;
  height: 34.8%;
  top: 34%;
  left: 61.4%;
  transition-duration: 1s;
  transform: rotate(270deg);
  cursor: pointer;
}

#countdown {
  position: relative;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
  transform: scale(2);
}

#countdownTitle {
  display: flex;
  position: absolute;
  top: 70%;
  -webkit-animation: bounceTitle 1s ease infinite;
          animation: bounceTitle 1s ease infinite;
}

#countdownTitle h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle3 {
  display: flex;
  position: absolute;
  top: 70%;
}

#countdownTitle3 h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle2 h4 {
  color: #fff;
  font-size: 4vw;
}

#countdown-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

#svg,
#svg2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  -webkit-animation: countdownArrival 0.5s linear forwards;
          animation: countdownArrival 0.5s linear forwards;
}

#svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  -webkit-animation: countdown 4.5s linear forwards;
          animation: countdown 4.5s linear forwards;
}

#svg2 circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  stroke-dashoffset: 0px;
}

@-webkit-keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@-webkit-keyframes countdownArrival {
  from {
    transform: scale(7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes countdownArrival {
  from {
    transform: scale(7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes bounceTitle {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes bounceTitle {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

/* OFFICE */

.clock-office img {
  position: absolute;
  z-index: 2;
  height: 8%;
  top: 15.5%;
  left: 43%;
}

.hat-office img {
  position: absolute;
  z-index: 2;
  height: 15%;
  top: 27%;
  left: 25%;
}

.coatrack-office img {
  position: absolute;
  z-index: 1;
  height: 38%;
  top: 30%;
  left: 24.5%;
}

.trash-office img {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 80.5%;
  left: 80%;
}

.desktoplamp-office img {
  position: absolute;
  z-index: 3;
  height: 21.5%;
  top: 50.5%;
  left: 33.3%;
}

.desktoppapers-office img {
  position: absolute;
  z-index: 3;
  width: 17%;
  top: 70.2%;
  left: 33.3%;
}

.desktoptoplamp-office img {
  position: absolute;
  z-index: 2;
  width: 31%;
  top: 3.2%;
  left: 32.7%;
}

.desktopdoor-office img {
  position: absolute;
  z-index: 2;
  height: 66%;
  top: 13.2%;
  right: 3.5%;
}

.desktopcupboard-office img {
  position: absolute;
  z-index: 2;
  height: 70%;
  top: 23.2%;
  left: -0.5%;
}

.desktopchair-office img {
  position: absolute;
  z-index: 1;
  height: 44%;
  top: 52.2%;
  left: 43%;
}

.desktoplockers-office img {
  position: absolute;
  z-index: 0;
  width: 32%;
  top: 23.6%;
  left: 27.2%;
}

.desktopdesktop-office img {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  width: 55%;
  bottom: 0;
  left: 19.5%;
}

.Notifications-Container {
  /* border: 2px solid white; */
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0%;
  z-index: 5;
}

.Notifications {
  /* padding: 10px; */
  padding-top: 1%;
  background-color: rgba(0, 0, 0, 12);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-family: Helvetica, Arial, sans-serif;
  transition: transform 0.5s;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  -webkit-animation: slide 5s ease;
          animation: slide 5s ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
}

.Notifications p {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.2rem;
  padding: 5px 0px 10px 0px;
}

@-webkit-keyframes slide {
  from {
    top: 0%;
    display: block;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    top: 0%;
    z-index: 0;
    opacity: 0;
  }
}

@keyframes slide {
  from {
    top: 0%;
    display: block;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    top: 0%;
    z-index: 0;
    opacity: 0;
  }
}

.Gameboard-container {
  border-radius: 5px;
  width: 60%;
  background: #66566d;
  margin: 0px 5px;
  cursor: url(/static/media/pink.a307ee05.cur), default;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Gameboard-container div,
.Gameboard-container img {
  cursor: url(/static/media/pink.a307ee05.cur), default;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
}

.Gameboard-title {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

.Gameboard-container h1,
.Gameboard-container h2,
.Gameboard-container h3 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 1.5rem !important;
  margin: 15px;
}

.Gameboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Gameinfo {
  margin: 10px 30px;
  padding: 10px;
  background: #2d1837;
}

.Gameinfo p {
  margin: 15px;
}

.YouAreHere {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
}

.YouAreHere h4 {
  font-family: 'Pangolin', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  transform: rotate(4deg);
  color: #fff;
  margin: 15px;
}

.Timer h1 {
  font-family: 'Roboto';
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}

.timer {
  font-family: 'Montserrat';
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;
}

/* GAMEBOARD HEADER */

.Gameboard-Header {
  position: absolute;
  width: 23%;
  height: 11%;
  z-index: 4;
  border-bottom-left-radius: 5% 15%;
  background: linear-gradient(90deg, #d4507a, #dc4b50);
  right: 0;
}

.GameBoardHeader_cards {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  height: 100%;
  cursor: pointer;
}

.GameBoardHeader_card {
  background-repeat: no-repeat;
}

.GameBoardHeader_card p {
  opacity: 0;
}

.card_primary {
  background-image: url(/static/media/mini_primary_emotion_card.b978f7d1.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}

.card_secondary_1 {
  background-image: url(/static/media/mini_emotion_card_1.45b7ecad.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_2 {
  background-image: url(/static/media/mini_emotion_card_2.9a2e3ba0.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_3 {
  background-image: url(/static/media/mini_emotion_card_3.d5d209fc.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.card_secondary_4 {
  background-image: url(/static/media/mini_emotion_card_4.67357dec.svg);
  display: flex;
  display: -webkit-flex;
  width: 20%;
  justify-content: center;
  -webkit-justify-content: center;
  background-position: center;
}
.GameBoardHeader_card_found {
  background-color: #2d1937;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  color: white;
  margin-top: 60%;
  font-size: smaller;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  opacity: 1;
}

.card_disabled {
  opacity: 0.3;
}

@font-face {
  font-family: 'ArcadeClassic';
  font-style: normal;
  font-weight: normal;
  src: local('ArcadeClassic'), url(/static/media/ARCADECLASSIC.5a1a4516.woff) format('woff');
}

@font-face {
  font-family: 'Karmatic Arcade';
  font-style: normal;
  font-weight: normal;
  src: local('Karmatic Arcade'), url(/static/media/ka1.385354ed.woff) format('woff');
}

.ActionScoreboard {
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url(/static/media/pink.a307ee05.cur), pointer;
  font-family: 'ArcadeClassic';
}

.ActionScoreboard:hover {
  background-position: right;
}

.Congrat-Input-DemoMulti {
  font-family: 'ArcadeClassic';
  font-size: 1.2rem;
}

.ModalTricky-Congrat-Container-DemoMulti {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 75%;
  height: 95%;
  border-radius: 5px;
}

.ModalTricky-Congrat-Container-DemoMulti img {
  position: relative;
  width: 70%;
}

.ModalTricky-Bravo {
  width: 60%;
  margin: -5px auto;
  text-align: center;
  position: relative;
  padding: 10px 0px;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  box-shadow: 0px 0px 5px #454545;
  border-radius: 10px/50%;
}

.ModalTricky-Bravo h2 {
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
}

.ModalTricky-congrat-p-DemoMulti {
  text-align: center;
  font-family: 'ArcadeClassic';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-congrat-Title-DemoMulti {
  text-align: center;
  font-family: 'Karmatic Arcade';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-Form-DemoMulti {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  width: 35%;
}

.Congrat-Bottom-DemoMulti {
  display: flex;
  width: 100%;
}

.ModalTricky-Fox {
  width: 50%;
  position: relative;
}

.Congrat-Input-Large-DemoMulti {
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  margin: auto;
  font-family: 'ArcadeClassic';
}

.Congrat-Input-Medium {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Large-DemoMulti input {
  margin: 5px auto;
  width: 80%;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  border: 2px solid #d9d9d9;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.Congrat-Input-Large input {
  width: 100%;
}

::-webkit-input-placeholder {
  color: #d9d9d9;
}

:-ms-input-placeholder {
  color: #d9d9d9;
}

::-ms-input-placeholder {
  color: #d9d9d9;
}

::placeholder {
  color: #d9d9d9;
}

/* FOX */

.FoxBody-Congrat img {
  z-index: 1;
  bottom: 4%;
  right: 22%;
  width: 50%;
  position: absolute;
}

.FoxBody-Congrat .FoxBody-Congrat {
  width: 350px;
  height: 20%;
  position: relative;
  bottom: 0;
}

.FoxHead-Congrat-DemoMulti {
  position: absolute;
  z-index: 2;
  bottom: 10%;
  right: 25%;
  width: 40%;
}

.FoxHead-Congrat-DemoMulti img {
  -webkit-animation: movinghead 10s ease infinite;
          animation: movinghead 10s ease infinite;
}

.FoxHead-Congrat-DemoMulti img:hover {
  -webkit-animation: movingheadfast 0.5s ease;
          animation: movingheadfast 0.5s ease;
}

.FoxHead-Tutorial img {
  z-index: 2;
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movinghead 10s ease infinite;
          animation: movinghead 10s ease infinite;
}

.FoxHead-Tutorial img:hover {
  bottom: 46%;
  right: 31%;
  width: 42%;
  -webkit-animation: movingheadfast 0.5s ease;
          animation: movingheadfast 0.5s ease;
}

@-webkit-keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}

@keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}

@font-face {
  font-family: 'ArcadeClassic';
  font-style: normal;
  font-weight: normal;
  src: local('ArcadeClassic'), url(/static/media/ARCADECLASSIC.5a1a4516.woff) format('woff');
}

.ModalTricky-Scoreboard-Container-DemoMulti {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
  border-radius: 5px;
  border: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  border-image-slice: 1;
}

.ModalTricky-Scoreboard-Scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background: #fff;
}

.ModalTricky-Scoreboard-info-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 15%;
  font-family: 'ArcadeClassic';
}

.ModalTricky-Scoreboard-Scores-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 22%;
  font-family: 'ArcadeClassic';
}

.ModalTricky-Scoreboard-DemoMulti {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
  width: 35%;
  height: 55%;
}

.ModalTricky-Scoreboard-Title-DemoMulti {
  text-align: center;
  font-family: 'Karmatic Arcade';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-Scoreboard-players-DemoMulti {
  width: 100%;
  display: flex;
  padding: 15px 0px 10px 0px;
  color: #fff;
  text-align: center;
}

.ModalTricky-Scoreboard-User-DemoMulti {
  width: 100%;
  display: flex;
  padding: 8px 0px 8px 0px;
  background-image: linear-gradient(
    90deg,
    #e76322,
    #d93a5b,
    #cf1c83,
    #792887,
    #552d88
  );
  color: #fff;
  text-align: center;
}

.ModalTricky-Scoreboard-players-DemoMulti:hover {
  background: #e8e8e8;
  font-weight: bold;
}

.ModalTricky-Scoreboard-players-DemoMulti:hover .col-20 {
  color: #666666;
}

.col-10 {
  width: 10%;
}

.col-20 {
  width: 20%;
  cursor: pointer;
}

.col-50 {
  width: 50%;
  text-align: left;
  padding-left: 15px;
}

.scoreboard-pseudo-DemoMulti {
  font-weight: bold;
  color: #e3505d;
}

.scoreboard-rank-DemoMulti {
  font-weight: bold;
  color: #4d3990;
}

.score-selected {
  border: 1px solid #fff;
  border-radius: 5px;
}

.scoreboard-user-DemoMulti {
  font-weight: bold;
  color: #fff;
}

.Dashboard-container {
  border: 3px solid transparent;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 20%;
  position: relative;
}

.Dashboard-container h2 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
  font-size: 1.3rem;
}

.Dashboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Dashboard-Logo {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
  padding: 20px 0px;
}

.Dashboard-Logo img {
  width: 50%;
  height: 100%;
}

.Dashboard-Logo h3 {
  font-family: 'Pangolin', Arial, Helvetica, sans-serif;
  color: #fff;
  position: absolute;
  margin: 0px;
  top: 4%;
  right: 10%;
  font-size: 1rem;
  transform: rotate(-6deg);
}

/* SOUND GAME */

.Sound-Game {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  padding: 10px 20px;
  background: #2d1837;
  margin: 5px;
  /* border: 4px solid #45354c; */
  opacity: 0.5;
  position: relative;
  cursor: pointer;
}

.Sound-Game img {
  width: 20px;
  height: 20px;
}

/* TURN COUNTER */

.TurnCounter-container,
.Time-container {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  background: #2d1837;
  margin: 5px;
  position: relative;
}

.Time-container {
  color: #fff;
  font-weight: 300;
}

.TurnCounter-container2 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  border: 4px solid red;
}

.TurnCounter-container2 h2 {
  background: none !important;
  margin-bottom: 5%;
}

.TurnCounter-container-time {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  padding: 30px 20px;
  border: 4px solid #45354c;
  position: relative;
  bottom: 0;
  left: 25%;
}

/* OBJECTS */

.Object-Item-Container {
  margin-bottom: 20px;
}

.Object-title {
  padding: 10px 0px 0px 10px;
  font-size: 1.5vw !important;
}

.ObjectList {
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  position: relative;
  background: #2d1837;
  margin: 5px;
}

.Object-square {
  width: 100px;
  height: 100px;
  background: #635868;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.Object-square img {
  width: 80px;
  height: 80px;
}

.Object-square .Object-Image {
  cursor: pointer;
}

.Object-name {
  padding: 10px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter .example-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave .example-leave-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.TurnCounter-container2 h2 {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 1rem !important;
  font-weight: 400 !important;
  opacity: 30%;
}

.timer .TimerText {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  opacity: 30%;
}

.timer .TimerText-time {
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10-time {
  text-transform: uppercase;
  text-align: center;
  color: #a30000;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 15%;
}

.timer .TimerText10 {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  position: absolute;
  top: 13%;
  color: #a30000;
}

.NbClick2 {
  font-size: 1.7rem;
  font-weight: 700 !important;
}

.NbClick2--big {
  font-size: 1.7rem;
  font-weight: 700 !important;
  -webkit-animation: big2 1s ease;
          animation: big2 1s ease;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

@-webkit-keyframes big2 {
  from {
    font-size: 1.7rem;
    font-weight: 700 !important;
  }
  to {
    font-size: 2.4rem;
    font-weight: 1200 !important;
  }
}

@keyframes big2 {
  from {
    font-size: 1.7rem;
    font-weight: 700 !important;
  }
  to {
    font-size: 2.4rem;
    font-weight: 1200 !important;
  }
}

.NbClick {
  font-size: 1rem;
  font-weight: 500 !important;
}

.NbClick--big {
  font-size: 1rem;
  font-weight: 500 !important;
  -webkit-animation: big 1s ease;
          animation: big 1s ease;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

@-webkit-keyframes big {
  from {
    font-size: 1rem;
    font-weight: 500 !important;
  }
  to {
    font-size: 2rem;
    font-weight: 1000 !important;
  }
}

@keyframes big {
  from {
    font-size: 1rem;
    font-weight: 500 !important;
  }
  to {
    font-size: 2rem;
    font-weight: 1000 !important;
  }
}

.UserBoard-container {
  border-radius: 5px;
  width: 20%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.UserBoard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 10px;
}

.UserBoard-Block {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
}

.UserBoard-Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  text-align: center;
}

.UserBoard-Modal button {
  width: 100%;
  margin: 10px 10px;
  padding: 10px 5px;
}

.UserBoard-Modal .ModalTricky-p {
  font-size: 1rem;
  margin: 0px;
}

.MGBoard-Block {
  display: flex;
  padding: 15px;
  margin: 50px 5px 5px 5px;
  justify-content: space-between;
}

.UserBoard-participant {
  display: flex;
  height: 15vh;
  position: relative;
  margin: 2px 5px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.UserBoard-Localparticipant {
  display: flex;
  height: 15vh;
  position: relative;
  margin: 2px 5px;
  background: #fff;
}

.UserBoard-Player {
  z-index: 1;
  margin: 0px 0px 5px 10px;
  position: absolute;
  bottom: 0%;
  display: inline-block;
}

.UserBoard-Admin-Player {
  z-index: 1;
  margin: 0px 0px 5px 10px;
  position: absolute;
  width: 50%;
  bottom: 0%;
  display: inline-block;
}

.UserBoard-Player-Coup {
  border: 1px solid #fff;
  border-radius: 15.5px;
  background: #fff;
  width: 2vw;
}

.UserBoard-Player-Name {
  margin: 0px 10px 0px 10px;
  width: 100%;
}

.UserBoard-Player-Name-Admin {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2%;
}

.UserBoard-Player-Name-Admin h4 {
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-participant img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoard-participant video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoard-Status p {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
}

.UserBoard-RightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-Panel {
  width: 100%;
  height: 100%;
}

#sign-out-userboard img {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 0px 0px 50%;
  color: #000;
  width: 7%;
  height: 16%;
  padding: 6px;
  background: #fff;
  font-size: 0.8rem;
}

#microphone-onboard img {
  position: absolute;
  bottom: 3%;
  right: 3%;
}

#microphone-onboard img {
  padding: 5px;
  color: #000;
  width: 7%;
  height: 16%;
  font-size: 0.8rem;
}

.portal-onboard {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  padding: 0px 0px 0px 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.46);
  box-shadow: 0 16px 48px 0 rgba(0, 55, 83, 0.2);
  border-radius: 23px;
}

.portal-onboard:hover {
  background: #fff;
}

.portal-onboard p:hover {
  color: #000;
}

.portal-onboard p {
  font-size: 0.8vw;
  color: #fff;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  padding: 5px;
}

#video-onboard img {
  position: absolute;
  bottom: 3%;
  right: 13%;
}

#video-onboard img {
  padding: 5px;
  color: #000;
  width: 7%;
  height: 16%;
}

/* REMOTE PARTICIPANT */

.UserBoard-RemotePlayer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 15vh;
  padding: 0px 0px 0px 10px;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-RemoteAdmin {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-Admin-RemotePlayer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  padding: 0px 0px 0px 10px;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
}

.UserBoard-RemotePlayer h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  text-shadow: 1px 1px 2px #000;
}

.UserBoardRemote-Webcam {
  display: flex;
  height: 130px;
}

.UserBoardRemote-Webcam img {
  width: 19.5%;
  height: 150px;
  object-fit: cover;
  position: absolute;
}

.UserBoardRemote-Webcam video {
  width: 18.9%;
  height: 150px;
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
}

.UserBoardRemote-Block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 15vh;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Block-Admin {
  display: flex;
  justify-content: space-between;
  width: 19.5%;
  margin-top: 10px;
  height: 15vh;
  position: absolute;
  top: 78vh;
}

.UserBoardRemote-Block-Admin-0 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Block-Admin-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 59%;
}

.UserBoardRemote-Block-Admin-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 37.5%;
}

.UserBoardRemote-Block-Admin-3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 16.5%;
}

.UserBoardRemote-Block-Admin-4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  height: 20%;
  position: relative;
  top: 0%;
}

.UserBoardRemote-Admin-Block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15vh;
  margin-top: 10px;
  position: relative;
}

.UserBoardRemote-Block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Block video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Admin-Block img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.UserBoardRemote-Admin-Block video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* SKELETON */

.UserBoard-Skelton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    60.64deg,
    #2a348d 0.99%,
    #cf1c83 50.78%,
    #ef7c00 98.25%
  );
}

.UserBoard-Skelton-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 230px;
}

.UserBoard-RightPanel-Type {
  position: absolute;
  top: 4%;
  left: 4%;
}

.UserBoard-RightPanel-Type h4 {
  font-size: 1rem;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-Remote-Player-Name-Admin {
  margin: 0px 10px 0px 10px;
  width: 100%;
}

.UserBoard-Remote-Player-Name-Admin h4 {
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-Remote-Participant-Admin {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.UserBoard-Game-Master {
  position: absolute;
  top: 5%;
  left: 15px;
}

.UserBoard-Game-Master h4 {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin: 0;
  text-shadow: 1px 1px 2px #000;
}

.UserBoard-TutorialStatus {
  position: absolute;
}

.UserBoard-Ready-Light {
  border: none;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 20px;
  height: 1.1vw;
  width: 1.1vw;
  border-radius: 50%;
  box-shadow: 0 0 1px var(--green), 0 0 5px var(--green), 0 0 10px var(--green),
    0 0 40px var(--green), inset 0 0 5px var(--green),
    inset 0 0 7px var(--green);
}

.UserBoard-NotReady-Light {
  border: none;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 20px;
  height: 1.1vw;
  width: 1.1vw;
  border-radius: 50%;
  box-shadow: 0 0 1px var(--red), 0 0 5px var(--red), 0 0 10px var(--red),
    0 0 40px var(--red), inset 0 0 5px var(--red), inset 0 0 7px var(--red);
}

.UserBoard-Admin-Player-Location {
  z-index: 1;
  position: absolute;
  display: inline-block;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  left: 30%;
  margin: 2px;
  padding: 4px;
  -webkit-animation: appear 0.5s ease-out 3;
          animation: appear 0.5s ease-out 3;
  transform-origin: top center;
}

.UserBoard-Admin-Player-Location h4 {
  font-size: 0.8vw;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

@-webkit-keyframes appear {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* BUBBLES */

.bubble {
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 5px #818181;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeIn 0.5s ease;
          animation: fadeIn 0.5s ease;
}

.bubble p {
  font-size: 1rem;
  color: #000;
  text-align: center;
}

.pos1 {
  position: absolute;
  z-index: 10;
  top: 25%;
  right: 70%;
}

.pos2 {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 70%;
}

.pos3 {
  position: absolute;
  z-index: 10;
  top: 70%;
  right: 70%;
}

.bubble::after {
  content: '';
  position: absolute;
  border-right: 0px solid transparent;
  border-left: 40px solid transparent;
  border-top: 20px solid #fff;
  top: 100px;
  right: 20%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Button button {
  color: #525252;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Button.Button-header button {
  margin: 0;
  padding: 0;
}

.Button a {
  text-decoration: none;
}

.Button.Button-header button .Button_line {
  height: 2px;
  width: 0;
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  margin-top: 5px;
}

/* Animation */
.Button.Button-header button:hover .Button_line {
  transition-duration: 0.5s;
  width: 100%;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Header_logo img {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.Input_label {
  display: flex;
  flex: 1 1;
}

.Input_input {
  flex: 1 1;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 20px;
}

.FinalStep {
  display: flex;
}

.FinalStep_container {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4.97326px 18.6497px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: 2s;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
}

.FinalStep_container_name {
  display: flex;
}

.FinalStep_resume {
  display: flex;
}

.FinalStep_resume_title {
  margin-left: 20px;
}

.FinalStep_left,
.FinalStep_right {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.InfoResponse {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
  background-color: white;
  padding: 20px;
  margin-right: 20px;
  border-radius: 8px;
  font-size: 14px;
}

.InfoResponse_goals {
  display: flex;
  text-align: center;
}

.InfoResponse_goals_title {
  display: flex;
  align-items: center;
}

.InfoResponse_goal {
  border: 1px solid #9e9e9e;
  padding: 10px;
  margin: 10px;
  flex: 1 1;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.Card {
  display: flex;
}

.Card_action {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
}

.Card_action_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.Card_action_title {
  font-weight: bold;
  margin: 20px 0;
}

.Card_action_plus {
  margin: 10px 0;
  color: white;
}

.Card_action_plus .Button button {
  background-color: rgba(113, 3, 236, 0.7);
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
  border: 1px solid rgba(113, 3, 236, 0.7);
}

.Card_action_plus .Button button:hover {
  background-color: white;
  border-radius: 8px;
  padding: 5px 10px;
  color: rgba(113, 3, 236, 0.7);
  border: 1px solid rgba(113, 3, 236, 0.7);
}

.Card_action_response {
  background: #fff;
  border-radius: 8px;
  transition: box-shadow 0.5s;
  flex: 1 1;
  cursor: pointer;
  margin: 0 20px;
  padding: 0px;
  max-width: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card_action_response:hover {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
}
.Card_action_response_no-active {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Card_action_response_active {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
}
.Card_action_response_disabled {
  display: none;
  transition-duration: 1s;
}

.Card_action_image {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.ConvertionTunnel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ConvertionTunnel_steps {
  display: flex;
  padding-top: 20px;
  align-items: flex-end;
}

.ConvertionTunnel_step {
  flex: 1 1;
  text-align: center;
  text-decoration: none;
  color: #525252;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.ConvertionTunnel_step_number_active {
  color: rgba(113, 3, 236, 0.7);
  font-weight: bold;
}

.ConvertionTunnel_step_number_past {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_name_active {
  color: #525252;
}

.ConvertionTunnel_step_index {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 30px;
  color: #9e9e9e;
}

.ConvertionTunnel_step_index_active {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_index:hover {
  color: rgba(113, 3, 236, 0.7);
}

.ConvertionTunnel_step_bar {
  height: 2px;
  margin: 10px 0;
  transition-duration: 1.5s;
  width: 0;
}

.ConvertionTunnel_step_bar_active {
  height: 2px;
  margin: 10px 0;
  background-color: rgba(113, 3, 236, 0.7);
  transition-duration: 1.5s;
  width: 100%;
}

.ConvertionTunnel_step_bar_past {
  height: 2px;
  margin: 10px 0;
  background-color: rgba(113, 3, 236, 0.7);
  transition-duration: 1.5s;
  width: 100%;
}

.ConvertionTunnel_question {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3 1;
  margin-left: -20000px;
  transition-duration: 1s;
}

.ConvertionTunnel_question_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3 1;
  margin-left: 0px;
  transition-duration: 1s;
  justify-content: center;
}

.ConvertionTunnel_question_description {
  background-color: white;
}

.ConvertionTunnel_responses {
  margin-top: 20px;
  display: flex;
}

.ConvertionTunnel_actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.ConvertionTunnel_action {
  text-decoration: none;
  border: 2px solid rgba(113, 3, 236, 0.7);
  padding: 5px 10px;
  color: rgba(113, 3, 236, 0.7);
  border-radius: 10px;
}

.ConvertionTunnel_action:hover {
  background-color: rgba(113, 3, 236, 0.7);
  color: white;
}

.ConvertionTunnel_action_prev {
  margin-left: 30px;
}

.ConvertionTunnel_action_next {
  margin-right: 30px;
}

.Univers {
  display: flex;
  flex-direction: column;
  height: 100%;
}

