.Notifications-Container {
  /* border: 2px solid white; */
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0%;
  z-index: 5;
}

.Notifications {
  /* padding: 10px; */
  padding-top: 1%;
  background-color: rgba(0, 0, 0, 12);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-family: Helvetica, Arial, sans-serif;
  transition: transform 0.5s;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  animation: slide 5s ease;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
}

.Notifications p {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.2rem;
  padding: 5px 0px 10px 0px;
}

@keyframes slide {
  from {
    top: 0%;
    display: block;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    top: 0%;
    z-index: 0;
    opacity: 0;
  }
}
