.Card {
  display: flex;
}

.Card_action {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
}

.Card_action_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.Card_action_title {
  font-weight: bold;
  margin: 20px 0;
}

.Card_action_plus {
  margin: 10px 0;
  color: white;
}

.Card_action_plus .Button button {
  background-color: rgba(113, 3, 236, 0.7);
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
  border: 1px solid rgba(113, 3, 236, 0.7);
}

.Card_action_plus .Button button:hover {
  background-color: white;
  border-radius: 8px;
  padding: 5px 10px;
  color: rgba(113, 3, 236, 0.7);
  border: 1px solid rgba(113, 3, 236, 0.7);
}

.Card_action_response {
  background: #fff;
  border-radius: 8px;
  transition: box-shadow 0.5s;
  flex: 1 1;
  cursor: pointer;
  margin: 0 20px;
  padding: 0px;
  max-width: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card_action_response:hover {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
}
.Card_action_response_no-active {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Card_action_response_active {
  box-shadow: 16px 16px 48px 16px rgba(0, 55, 83, 0.2);
  border: 1px solid rgba(113, 3, 236, 0.7);
}
.Card_action_response_disabled {
  display: none;
  transition-duration: 1s;
}

.Card_action_image {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
