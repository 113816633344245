@font-face {
  font-family: 'ArcadeClassic';
  font-style: normal;
  font-weight: normal;
  src: local('ArcadeClassic'), url('ARCADECLASSIC.woff') format('woff');
}

@font-face {
  font-family: 'Karmatic Arcade';
  font-style: normal;
  font-weight: normal;
  src: local('Karmatic Arcade'), url('ka1.woff') format('woff');
}

.ActionScoreboard {
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url('../../../styles/img/Game/pink.cur'), pointer;
  font-family: 'ArcadeClassic';
}

.ActionScoreboard:hover {
  background-position: right;
}

.Congrat-Input-DemoMulti {
  font-family: 'ArcadeClassic';
  font-size: 1.2rem;
}

.ModalTricky-Congrat-Container-DemoMulti {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 75%;
  height: 95%;
  border-radius: 5px;
}

.ModalTricky-Congrat-Container-DemoMulti img {
  position: relative;
  width: 70%;
}

.ModalTricky-Bravo {
  width: 60%;
  margin: -5px auto;
  text-align: center;
  position: relative;
  padding: 10px 0px;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  box-shadow: 0px 0px 5px #454545;
  border-radius: 10px/50%;
}

.ModalTricky-Bravo h2 {
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
}

.ModalTricky-congrat-p-DemoMulti {
  text-align: center;
  font-family: 'ArcadeClassic';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-congrat-Title-DemoMulti {
  text-align: center;
  font-family: 'Karmatic Arcade';
  color: #fff;
  margin: 0px auto;
  font-size: 1.4rem;
}

.ModalTricky-Form-DemoMulti {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  width: 35%;
}

.Congrat-Bottom-DemoMulti {
  display: flex;
  width: 100%;
}

.ModalTricky-Fox {
  width: 50%;
  position: relative;
}

.Congrat-Input-Large-DemoMulti {
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  margin: auto;
  font-family: 'ArcadeClassic';
}

.Congrat-Input-Medium {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Large-DemoMulti input {
  margin: 5px auto;
  width: 80%;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  border: 2px solid #d9d9d9;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.Congrat-Input-Large input {
  width: 100%;
}

::placeholder {
  color: #d9d9d9;
}

/* FOX */

.FoxBody-Congrat img {
  z-index: 1;
  bottom: 4%;
  right: 22%;
  width: 50%;
  position: absolute;
}

.FoxBody-Congrat .FoxBody-Congrat {
  width: 350px;
  height: 20%;
  position: relative;
  bottom: 0;
}

.FoxHead-Congrat-DemoMulti {
  position: absolute;
  z-index: 2;
  bottom: 10%;
  right: 25%;
  width: 40%;
}

.FoxHead-Congrat-DemoMulti img {
  animation: movinghead 10s ease infinite;
}

.FoxHead-Congrat-DemoMulti img:hover {
  animation: movingheadfast 0.5s ease;
}

.FoxHead-Tutorial img {
  z-index: 2;
  bottom: 46%;
  right: 31%;
  width: 42%;
  animation: movinghead 10s ease infinite;
}

.FoxHead-Tutorial img:hover {
  bottom: 46%;
  right: 31%;
  width: 42%;
  animation: movingheadfast 0.5s ease;
}

@keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}
