.Bureau-img {
  position: relative;
  width: 80%;
  z-index: 0;
  overflow: hidden;
}

.Bureau-img-div:focus {
  outline: none;
}

.CloseWindow img {
  position: absolute;
  width: 5%;
  top: 5%;
  right: 5%;
}

.power-chord img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 21%;
  top: 72%;
  left: 13%;
}

.power-chord-plugged img {
  pointer-events: visible;
  position: absolute;
  z-index: 1;
  height: 27%;
  top: 64%;
  left: 13%;
}

.screen img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenPassword img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenEmail img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

.screenSleep img {
  position: absolute;
  z-index: 3;
  height: 21%;
  top: 47.5%;
  left: 50%;
  cursor: pointer;
}

/* FOLDER */

#letter {
  height: 80% !important;
}

.folder img {
  position: absolute;
  z-index: 1;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
  cursor: pointer;
}

.hide-folder img {
  position: absolute;
  z-index: 2;
  height: 13.4%;
  top: 28.9%;
  left: 33%;
}

.letterroom img {
  position: absolute;
  animation: zoomleftmulti 0.5s linear;
  animation-fill-mode: forwards;
  display: block;
  z-index: 1;
}

.hide-folder {
  animation: hidefolder 1s linear;
  animation-fill-mode: forwards;
}

@keyframes hidefolder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

/* BOARD */

.board img {
  position: absolute;
  z-index: 0;
  height: 37.4%;
  top: 19.9%;
  left: 66.5%;
  cursor: pointer;
}

.modal-email {
  margin-top: 200px;
  background: #23345a;
  border: 10px solid #000000;
  border-radius: 20px;
  max-width: 1000px;
  min-height: 100px;
  padding: 25px;
  position: relative;
}

.email-container-image img {
  width: 90%;
}

#mug {
  height: 80% !important;
}

.mug img {
  position: absolute;
  z-index: 3;
  height: 8.5%;
  top: 61%;
  left: 41%;
  cursor: pointer;
}

.mug2 img {
  position: absolute;
  z-index: 3;
  height: 6.5%;
  top: 70%;
  left: 77%;
  cursor: pointer;
}

.phone img {
  position: absolute;
  z-index: 4;
  height: 13%;
  top: 64.4%;
  left: 25%;
  cursor: pointer;
}

.mug #smoke {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  animation: smoke 2s infinite;
  transform: rotate(18deg);
}
.mug #smoke2 {
  background: none;
  position: absolute;
  left: 36.5%;
  z-index: 2;
  top: 49%;
  width: 20%;
  height: 20%;
}
.mug #smoke2:after {
  content: '';
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  background-image: -webkit-radial-gradient(
      42% 48%,
      ellipse,
      rgba(255, 255, 255, 0.3),
      transparent 25%
    ),
    -webkit-radial-gradient(35% 70%, ellipse, rgba(255, 255, 255, 0.3), transparent
          15%),
    -webkit-radial-gradient(42% 61%, ellipse, rgba(255, 255, 255, 0.3), transparent
          10%);
  animation: smoke2 5s infinite;
  transform: rotate(18deg);
}
@keyframes smoke {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -20px -25px, -5px -25px, 0px -25px, 0px -25px;
    opacity: 0;
  }
}
@keyframes smoke2 {
  0% {
    background-position: 0 0;
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    background-position: -10px 15px, 5px -15px, 0px 15px, 0px 15px;
    opacity: 0;
  }
}

/* MODALS */

.modal-body img {
  margin: 50px;
}

.hidden {
  display: none;
}

.Hide-Button {
  background-color: transparent;
  border: none;
  cursor: none;
}
