.DigitalRoomVideo {
  position: relative;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DigitalRoomVideo-Grey {
  width: 100%;
  background: #e0e0e0ff;
}

.DigitalRoomVideo-Title {
  text-align: center;
  font-family: 'Montserrat', Tahoma, Verdana, sans-serif;
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.DigitalRoomVideo-Title h2 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem !important;
  margin: 30px auto;
}

.DigitalRoomVideo-Description {
  margin-bottom: 20px;
}

.DigitalRoomVideo-Description h3,
.DigitalRoomVideo-Description p {
  color: #525252;
  text-align: center;
}

.DigitalRoomVideo-Description h3 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  margin: 0px auto;
}

.DigitalRoomVideo-Description p {
  margin-top: 10px;
  font-size: 1rem;
}

.DigitalRoomVideo-Video {
  margin: 20px auto;
}

.DigitalRoomVideo-Video video {
  width: 900px;
  height: 438px;
  object-fit: cover;
}

.DigitalRoomVideo-VideoFrame {
  position: relative;
}

.fa-volume-up {
  position: absolute;
  bottom: 3%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

.fa-volume-mute {
  position: absolute;
  bottom: 3%;
  right: 1%;
  cursor: pointer;
  z-index: 1;
  color: #fff;
}

.DigitalRoomVideo-Button {
  margin: 0px auto 50px auto;
}

.DigitalRoomVideo-Button button {
  color: #fff;
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-size: 200%;
  background-position: left;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 -40px -40px -40px;
  transition: background-position ease-out 0.5s;
  cursor: pointer;
}

.DigitalRoomVideo-Button button:hover {
  background-position: right;
}

/* HOW IT WORKS */

.DigitalRoomVideo-Howitworks {
  display: flex;
  margin-bottom: 50px;
}

.DigitalRoom-AllSteps {
  display: flex;
  flex-direction: column;
}

.DigitalRoom-Text h3 {
  color: #e3505d;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.DigitalRoom-Text p {
  margin-top: 0px;
  padding-left: 30px;
}

.DigitalRoom-Step {
  display: flex;
}

.DigitalRoomVideo-DivNumber {
  margin-right: 20px;
}

.DigitalRoomVideo-AllNumbers {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 30px;
}

.DigitalRoomVideo-Number {
  margin-top: 10px;
  color: #e3505d;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: 'Montserrat', Tahoma, Verdana, sans-serif;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #e3505d 4px solid;
  background-color: #fff;
  border-radius: 50%;
}

/* ABOUT */

.DigitalRoomVideo-AllArguments {
  display: flex;
  margin-bottom: 50px;
}

.DigitalRoomVideo-Argument {
  margin-top: 30px;
  width: 33%;
}

.DigitalRoomVideo-Argument h3,
.DigitalRoomVideo-Argument p {
  color: #525252;
  text-align: center;
}

.DigitalRoomVideo-Argument span {
  text-align: center;
  font-size: 4rem;
  color: #525252;
}

.DigitalRoomVideo-Argument h3 {
  margin-bottom: 0px;
  text-transform: uppercase;
}

.DigitalRoomVideo-Argument p {
  margin-top: 10px;
}

.DigitalRoomVideo-Spans {
  display: flex;
  justify-content: center;
}

.DigitalRoomVideo-Argument:hover h3 {
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.DigitalRoomVideo-Argument:hover span {
  background: -webkit-linear-gradient(left, #8062eb, #e3505d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* THEY TRUST US */

.DigitalRoomVideo-Partner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.DigitalRoomVideo-Partner img {
  width: 200px;
  filter: grayscale(100%);
}

.DigitalRoomVideo-Partner img:hover {
  filter: grayscale(0%);
}

@media screen and (max-width: 1300px) {
  .DigitalRoomVideo-Video video {
    width: 700px;
    height: 342px;
  }
}

@media screen and (max-width: 990px) {
  .DigitalRoomVideo-Video video {
    width: 600px;
    height: 292px;
  }
}

@media screen and (max-width: 750px) {
  .DigitalRoomVideo-Video video {
    width: 270px;
    height: 131px;
  }
}
