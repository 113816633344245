#countdown {
  position: relative;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
  transform: scale(2);
}

#countdownTitle {
  display: flex;
  position: absolute;
  top: 70%;
  animation: bounceTitle 1s ease infinite;
}

#countdownTitle h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle3 {
  display: flex;
  position: absolute;
  top: 70%;
}

#countdownTitle3 h4 {
  color: #fff;
  font-size: 2vw;
}

#countdownTitle2 h4 {
  color: #fff;
  font-size: 4vw;
}

#countdown-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

#svg,
#svg2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  animation: countdownArrival 0.5s linear forwards;
}

#svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  animation: countdown 4.5s linear forwards;
}

#svg2 circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  stroke-dashoffset: 0px;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@keyframes countdownArrival {
  from {
    transform: scale(7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceTitle {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}
